@import "styles/colours.scss";

.tagInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  padding: 8px;

  border: 1px solid $sysBorderPrimary;
  border-radius: 4px;

  cursor: text;

  &:focus-within {
    border-color: $sysPrimaryDefault;
  }

  transition: border-color 0.3s linear;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.inputWrapper {
  position: relative;
}

.input {
  border: none;
  background: transparent;
  outline: none;
  font-size: 14px;
  min-width: 20px;
  flex: 1;

  &::placeholder {
    color: $sysTextSecondary;

    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }
}

.hiddenText {
  visibility: hidden;
  position: absolute;
  white-space: pre;
  font-size: 14px;
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12px;
  height: 12px;
}
