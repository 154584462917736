@import "styles/colours.scss";
@import "styles/constants.scss";

.header {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  border-bottom: 1px solid $grey50;
  gap: 8px;
  height: $previewHeaderHeight;
  width: 100%;
}

.actions {
  display: flex;
  gap: 8px;
}

.downloadIcon {
  color: $colorPrimary;
}

.ellipsisWrapper {
  display: grid;
  min-width: 0;
}

.title {
  flex: 1;
  font-size: 24px;
  font-weight: 700;
  max-width: 1280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
