@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  background-color: #f1f3f5; // to match the innate bg color used by Apryse

  h3 {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 8px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
  }
}

.info {
  font-size: 14px;
  padding-bottom: 16px;
  max-width: 390px;
  margin: 0;
}

.folderImg {
  width: 200px;
}

.download {
  background-color: $colorPrimary;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - var(--toolbar-width));
  height: 100%;
  background-color: #f1f3f5; // to match the bg color used by Apryse
  display: flex;
  justify-content: center;
  align-items: center;
}
