@import "styles/media_queries.scss";
@import "styles/colours.scss";

.emailVerificationPage {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 396px;
}

.emailVerificationcontainer {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}

.actionItemsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  border: 0px !important;
  font-weight: 400;
  line-height: 22px;
  color: $text-primary;
  font-size: 14px;
}

.headerTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}

.resendEmailButton {
  color: #2569f9;
}

.description {
  color: $grey;
}
.stotlesEmailLink {
  font-weight: 700;
}

.icon {
  margin-left: 4px;
  width: 16px;
  height: 16px;
}
