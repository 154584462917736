@import "./../../styles/colours.scss";

.table {
  :global(.ant-table-body) {
    background-color: $white;
  }
}

.supplierCell {
  padding: 1px 8px;
  border-radius: 2px;
}

.section {
  padding-bottom: 0px;
}

.nameColumnLink {
  color: $link-blue-default;
}
