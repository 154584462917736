@import "styles/colours";

.filterBar {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .filterItems {
    display: flex;
    justify-content: space-between;

    .searchInput {
      padding-right: 8px;
    }

    .filterIcon {
      color: $colorPrimary;
    }
  }

  .columnsAndExport {
    display: flex;
    align-items: center;

    .editColumnsButton {
      padding-right: 8px;
    }

    .clearSelected {
      padding-right: 8px;
      margin-right: 8px;
    }
  }
}

.viewFilters {
  display: flex;
  column-gap: 7px;
  align-items: baseline;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}
