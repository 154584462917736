@import "styles/colours.scss";
@import "styles/media_queries.scss";

.reportLayout {
  flex-direction: column;
  max-width: 1280px;

  background-color: $white;
  margin: 0 auto;
  padding: 0 64px 32px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    padding: 0;
  }

  .reportHeader {
    padding-bottom: 64px;
  }

  .reportBody {
    color: $colorText;
    display: flex;
    flex-direction: row;

    .reportAuthor {
      .ctaAuthor {
        flex-direction: column;
        border: 1px solid $grey50;
        border-radius: 8px;
        width: 400px;
        padding: 32px;

        .copy {
          padding-bottom: 24px;
          .aiIcon {
            width: 100px;
            // We will need the below code to re-add the geo icon when identity is established
            // border-radius: 6px;
            // padding: 18px;
            // background: linear-gradient(90deg, #003cba 0%, $mid-blue 100%);
            // text-align: center;

            .sparkles {
              height: 49px;
              width: 49px;
            }
          }

          h1 {
            padding-top: 24px;
            font-weight: 700;
          }

          p {
            padding-top: 8px;
          }

          .visitStotlesLinkContainer {
            padding-top: 14px;
            padding-bottom: 14px;

            .visitStotlesLink {
              padding-left: 0;
              border: 0;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }

        .shareSection {
          display: flex;
          flex-direction: row;
          border-top: 1px solid $grey50;
          padding-top: 24px;
          color: $grey900;

          .shareSpan {
            font-size: 16px;
            font-weight: 600;
            padding-right: 8px;
          }

          .shareIcon {
            width: 32px;
            height: 32px;
            background-color: $colorText;
            border-color: $colorText;
            border-radius: 8px;
            text-align: center;
            padding-top: 6px;
            color: $white;
            margin-right: 8px;
          }

          .shareIcon:hover {
            background-color: $blue600;
            border-color: $blue600;
          }
        }
      }
    }

    .reportText {
      padding-left: 80px;
      // width: 50vw;

      .reportAiResponse {
        padding-bottom: 36px;
      }

      .ctaSupplier {
        border-radius: 8px;
        padding: 64px 64px 64px 64px;
        background: linear-gradient(230deg, #ffd86c 0%, #ffc932 100%);

        .geoImage {
          position: absolute;
          z-index: -0.5;
          margin-left: -120px;
          margin-top: -256px;
        }

        h2 {
          position: relative;
          font-weight: 700;
          z-index: 1;
        }

        p {
          position: relative;
          z-index: 1;
        }

        .primaryCta {
          background-color: $colorText;
          border-radius: 0;
          margin-right: 8px;
          margin-bottom: 8px;
          z-index: 1;
        }

        .primaryCta:hover {
          background-color: $v2-dark-blue;
        }

        .secondaryCta {
          border-color: $colorText;
          color: $colorText;
          border-radius: 0;
          background-color: rgba(0, 0, 0, 0);
          z-index: 1;
        }

        .secondaryCta:hover {
          background-color: rgba($v2-dark-blue, 0.1);
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  break-after: avoid;
  page-break-after: avoid;
}
