@import "styles/colours.scss";

.frameworkProvider {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .frameworkName {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    align-items: center;
    white-space: nowrap;
  }

  .verifiedProvider {
    min-width: 16px;
  }
}

.titleText {
  font-weight: bold;
}

.providerText {
  color: $v2-text-secondary;
}

.tooltip {
  max-width: 350px;
}

.frameworkIcon {
  flex-shrink: 0;
}

.supplierCount {
  font-weight: 500;
  margin-bottom: 4px;
}

.lotCount {
  font-size: 12px;
}
