@import "styles/colours.scss";

.linksContainer {
  display: flex;
  align-items: center;
  gap: 16px;

  .logInLink {
    padding: 5px 12px;
    align-items: center;
    font-size: 14px;
  }

  .signUpButton {
    padding: 5px 12px;
    margin-bottom: 12px;
    border: 1px solid $grey50;
    color: $sysTextDefault;
  }
}
