@import "styles/colours.scss";

.feedbackInput {
  padding-bottom: 8px;
}

.checkboxContainer {
  padding-bottom: 8px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: right;

  .okButton {
    margin-left: 8px;
    width: fit-content;
    background-color: $text-dark-blue;
    color: white;
  }
}
