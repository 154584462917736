@import "styles/colours.scss";

// Custom Stotles themed bullet design targets any lists passed in as children
.styledListWrapper {
  ul {
    list-style: none; /* Remove default bullets */
    padding-left: 20px;
    margin-bottom: 0px;
  }

  ul li::before {
    content: "\2022"; /* \2022 is the CSS Code/unicode for a bullet */
    color: $mid-blue;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  li em {
    font-style: italic;
    font-weight: 700;
  }
}
