@import "styles/colours.scss";
@import "styles/layouts";
@import "styles/fonts";

.noPaddingBottom {
  padding-bottom: 0px;
}

.menuTabs {
  font-size: 14px;
  font-weight: 500;
  border-bottom: none;
  color: $dark-grey;
  margin-top: 16px;

  .menuTabItem {
    padding: 0px 16px;
  }
}

.paywallBanner {
  padding-left: 0;
  padding-right: 0;
}
