@import "styles/colours.scss";
@import "styles/media_queries.scss";
@import "styles/ant_utils.scss";

//TODO: Consider using a grid
.onboardingContainer {
  display: flex;
  justify-content: center;
  min-width: 100vw;

  .onboarding {
    display: flex;
    width: 100%;
    max-width: 1512px;
    height: 100%;

    .leftPane,
    .rightPane {
      position: relative;
      @media (max-width: map-get($breakpoints, lg)) {
        width: 100%;
      }
    }

    .leftPane {
      min-width: 50%;
      max-width: 564px;
      padding: 56px 96px;
      // total element width adds up to 756px
      max-height: calc(100vh - 64px);
    }

    .rightPane {
      min-width: 50%;
      max-width: 660px;
      padding: 48px;
      // total element width adds up to 756px
    }

    &.hideRightPane {
      .leftPane {
        width: 100%;
      }
      .rightPane {
        display: none;
      }
    }

    .leftPaneInner {
      overflow-y: auto;
      max-height: calc(100vh - 176px);

      @include no-scrollbar;
    }

    .rightPane {
      max-height: calc(100vh - 64px);
      @include no-scrollbar;
      @media (max-width: map-get($breakpoints, lg)) {
        display: none;
      }

      .rightImageContainer {
        background-size: cover;
        height: calc(100vh - 161px);
        max-height: 830px;

        border-radius: 16px;
        display: flex;
        align-items: flex-end;

        .content {
          .companyLogo {
            width: 200px;
            height: 40px;
            margin-bottom: 16px;
          }

          .quote {
            color: white;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 16px;
          }
          .quoteAuthor {
            color: white;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0;
          }

          .quoteAuthorTitle {
            color: white;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            margin: 0;
          }
        }

        .rightImage {
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding-right: 48px;
          padding-left: 48px;
        }
      }

      .authorisation {
        padding: 48px;
        background-image: url("../../../assets/images/onboarding/authorisation_background.svg");
        background-position-y: 30%;
      }

      .keywords {
        background-image: url("../../../assets/images/onboarding/geometric_keywords.svg");
      }

      .teams {
        background-image: url("../../../assets/images/onboarding/teams_background.png");
      }
    }

    .progressMeterContainer {
      background-color: $white;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 32px;
    }

    .populatingFeed {
      background-color: $white;
      margin: 16px 16px 0 16px;
      padding: 16px;
      position: relative;
      font-size: 18px;
      line-height: 26px;

      .spinner {
        margin-left: 10px;
      }
    }
  }
}

.loadingHeader {
  color: $sysTextDefault;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.loadingBody {
  color: $sysTextSecondary;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
