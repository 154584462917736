@import "styles/colours.scss";

.tooltip {
  max-width: 350px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  white-space: nowrap;

  .callOffName {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    align-items: center;
    font-weight: 500;
  }

  .buyerText {
    color: $v2-text-secondary;
  }
}
