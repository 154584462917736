@import "styles/colours.scss";
@import "styles/layouts";
@import "styles/fonts.scss";

.header {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 0;
}
