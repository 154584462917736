@import "../../styles/colours";

.collapsibleTreeList {
  max-height: 0px;
  overflow: hidden;

  transition: max-height 0.25s ease-in-out;

  &.expanded {
    max-height: 100%;
    padding-left: 36px;
  }
}

.container {
  padding: 12px;
  background-color: $white;
}

.treeList {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 2px;
}

.listItem {
  list-style: none;
  white-space: nowrap;
  margin: 4px 0px;
}

// Emulates ant tree styles for switcher
.switcher {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}

.collapseIcon {
  font-size: 12px !important; // otherwise overriden by ant dropdown menu
  line-height: 0;
}

.input {
  margin-bottom: 8px;

  i {
    font-size: 12px;
  }
}

.childlessCheckbox {
  margin-left: 24px;
}

.checkbox {
  min-height: 30px;
}
