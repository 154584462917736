@import "styles/colours.scss";

.emailConfirmationPage {
  display: flex;
  width: 100%;
  justify-content: center;
  .innerWrapper {
    text-align: center;
    max-width: 600px;
    margin-top: 20vh;
  }

  .heading {
    font-size: 48px;
    color: $dark-blue;
    font-weight: 400;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin-bottom: 50px;
  }

  .loginLink {
    font-weight: 700;
    display: block;
    font-size: 16px;
  }
}
