@import "styles/colours.scss";

.relevanceScoreButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 8px;
}

.relevanceScoreButton {
  display: flex;
  vertical-align: middle;
  gap: 8px;
}

.selected {
  background-color: $blue50;
  border-radius: 4px;
  border: 1px solid $colorPrimary;
}
