@import "styles/colours.scss";
@import "styles/media_queries.scss";

.container {
  flex: 1;
  background-color: white;
  margin: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: $dark-grey;

  h1 {
    font-size: 48px;
    color: $v2-dark-blue;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 48px;
    color: inherit;
  }

  p {
    font-size: 18px;
  }
}

.resolvedBuyers {
  display: grid;
  grid-template-columns: fit-content(440px);

  column-gap: 16px;
  row-gap: 16px;
  justify-content: center;
  margin-bottom: 120px;
}

.reportButton {
  padding: 4px;
}

.reportMessage {
  color: $grey;
}
