@import "styles/colours.scss";

.progressBar {
  max-width: 200px;
  margin-bottom: 56px;
}

.header {
  margin-bottom: 40px;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $text-primary-v2;
  }

  .subTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $v2-text-secondary;
  }
}

.teams {
  .team {
    border: 1px solid $grey50;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;

    .teamDetails {
      display: flex;
      flex-direction: column;

      .teamName {
        font-weight: 700;
        color: $text-primary-v2;
        font-size: 16px;
        line-height: 24px;
      }

      .teamMembers {
        margin: 0;
        color: $v2-text-secondary;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .joinButton {
      align-self: center;
    }
  }
}

.createTeamSection {
  .createTeamButton {
    color: $colorPrimary;
  }
}

.supportSection {
  margin-top: 56px;

  .supportButton {
    color: $colorPrimary;
    padding: 0;
  }
}

.modalBodyText {
  font-size: 14px;
  line-height: 22px;
  color: $v2-text-secondary;
}
