@import "styles/colours.scss";

.notifications {
  display: grid;
  gap: 32px;

  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $dark-blue;
    margin: 0;
  }

  h3 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: $dark-grey;
    margin: 0;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $grey;
    margin: 0;
  }
}

.notificationTypes {
  display: grid;
  gap: 8px;
}

.settingsLink {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $legacy-border-grey;
  padding: 16px;
  color: $dark-grey;
  i {
    align-self: center;
  }
}

.allSwitch {
  display: grid;
  grid-template-columns: 1fr max-content;
}
