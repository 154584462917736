.modal {
  max-height: 600px;
  overflow-y: scroll;
}

.form {
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding-top: 10px;
}

.actions {
  display: flex;
  gap: 8px;
  align-self: end;
}
