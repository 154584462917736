@import "styles/colours.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search {
  width: 320px;
}

.actionsContainer {
  display: flex;
  gap: 4px;
}

.descriptionContainer {
  margin-top: 8px;
  white-space: initial;
  max-height: 65px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, $black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, $black 50%, transparent 100%);

  p {
    line-height: 20px;
  }
}

.primaryBtn {
  display: flex;
  align-items: center;
  max-width: fit-content;
}

.externalLink {
  height: 16px;
  width: 16px;
}

.aiMarker {
  padding-left: 4px;
}
