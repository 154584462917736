@import "../form_components/Inputs.module.scss";
@import "../filter_form/SharedFilterForm.module.scss";

.form {
  @extend .flexColumn;
  width: 100%;
}

.filterSection {
  @extend .form;
  gap: 16px;
}

.inputContainer {
  @extend .flexColumn;
}

.inputLabel {
  @extend .label;
}

.formContent {
  @extend .formContent;
}

.divider {
  margin: 8px 0px;
}
