@import "styles/colours.scss";

.questionAndAns {
  display: grid;
  grid-template-columns: minmax(150px, 400px) minmax(200px, 500px) !important;
  align-items: start;
  gap: 24px;
  padding: 16px 0;
}

.answerContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 500px;
}

.icon {
  margin: 0px 12px;
  font-size: 16px;
  color: $sysPrimaryDefault;
}

.questionTitle {
  font-weight: 700 !important;
}

.divider {
  margin: 0;
}

.sources {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 11px;
}
