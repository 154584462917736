@import "../../styles/colours.scss";

.spinner {
  margin: 20px;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: repeat(5, max-content);
  align-items: center;
  gap: 16px;

  button {
    padding: 0;
  }
}

.summary {
  margin-top: 16px;
}
