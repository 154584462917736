@import "styles/colours.scss";

.documentGroupTitle {
  font-weight: 400;
  font-size: 18px;
  margin-top: 8px;
}

.tableDocRow {
  cursor: pointer;
}

.documentTable {
  margin-bottom: 8px;
  width: 100%;
}

.viewDocumentsButton {
  margin: 8px 0px;
}

.title {
  color: $link-blue-default;
}
