@import "styles/colours";

.container {
  // dashed border
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%230000004F' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  padding: 16px;
  min-width: 466px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: $grey10;
  padding: 32px;
}

.containerError {
  @extend .container;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FF0000CF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.instructionText {
  text-align: center;
  margin-top: 4px;
  margin-bottom: 4px;
}

.errorText {
  @extend .instructionText;
  color: #ff0000cf;
}

.tooltipIcon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.file {
  display: flex;
  width: 450px;
  align-items: center;
  justify-content: space-between;
}
