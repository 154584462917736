@import "styles/colours.scss";

.progressBar {
  max-width: 200px;
  margin-bottom: 56px;
}

.header {
  margin-bottom: 40px;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $text-primary-v2;
  }

  .subTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $v2-text-secondary;
  }
}

.teamNameInput {
  margin-bottom: 40px;

  input {
    width: 320px;
  }
}

.backButton {
  color: $colorPrimary;
  margin-top: 16px;
  padding-left: 0;
}
