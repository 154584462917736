@import "styles/colours";
@import "styles/layouts";

.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.inlineTitleActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.placeholderIcon {
  background-color: $app-bg-grey;
  padding: 8px;
  width: 40px;
}

.title {
  display: inline-flex;
  align-items: center;
  grid-column-gap: 16px;

  h1 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $dark-blue;
  }

  img {
    border-radius: 1px;
    // for alt text
    font-size: 7px;
    overflow: hidden;
  }
}

.stats {
  display: flex;
  gap: 24px;
}

.statistic {
  display: flex;
  gap: 2px;
}
