@import "styles/colours";
@import "styles/layouts";
@import "styles/fonts";

.buyerTitle {
  padding: 0px;
  margin-bottom: 0px;
  border: none;
  background-color: $white;
  font-weight: 700;
  font-size: 24px;
  color: $text-primary-v2;
  cursor: default;
}

.buyerTable {
  padding: 24px;
}
