@import "styles/colours.scss";

.title {
  font-size: 20px;
  line-height: 24px;
  color: $dark-grey;
  margin-bottom: 24px;
  font-weight: 700;
}

.subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: $dark-grey;
  margin-bottom: 8px;
}

.textBlock {
  font-size: 16px;
  line-height: 24px;
  color: $grey;
  margin-bottom: 24px;
}

.adminMessage {
  margin-bottom: 24px;
}

.setUpButton {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: $text-primary-v2;
}

.icon {
  height: 16px;
  width: 16px;
}
