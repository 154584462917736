@import "styles/colours.scss";
@import "styles/fonts";
@import "styles/layouts";

.headerBar {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .titleContainer {
    .pageHeader {
      font-size: 24px;
      font-weight: 700;
      padding-top: 4px;
      margin: 0;
    }
  }

  .ctaContainer {
    gap: 8px;
    display: flex;
    margin-top: 8px;
  }
}

.searchInput {
  width: 300px;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $default-page-border;
  margin-bottom: 0px;
}

.bulkActionBtns {
  display: flex;
  gap: 8px;
}

.noAccountsMessage {
  color: $text-primary-v2;
  font-size: 14px;
  line-height: 20px;
}
