@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.tagLabel {
  display: flex;
  gap: 4px;
}

.icon {
  color: $sysPrimaryDefault;
}
