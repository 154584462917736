@import "styles/fonts.scss";
@import "styles/colours.scss";

.errorHeader {
  color: $text-primary-v2;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.errorDesc {
  color: $text-secondary;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
