.select {
  width: 100%;

  > div > div {
    display: flex;
    gap: 8px;

    padding: 8px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :focus-within {
      margin: 0;
    }
  }
}
