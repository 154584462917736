.container {
  height: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 100px;

  h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-weight: bold;
    padding-top: 30px;
  }
}

.btnContainer {
  display: flex;
  gap: 8px;
  font-weight: 500;
}

.btn {
  display: flex;
  align-items: center;
}

.link {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.textContainer {
  width: 350px;
  text-align: center;
}
