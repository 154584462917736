@import "styles/colours.scss";

.buyerCleaningPage {
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  width: 100vw;

  .pageContent {
    position: relative;
    padding: 16px;
    background-color: $app-bg-grey;
    height: calc(100% - 57px);
  }

  .cancelAndReport {
    color: $red;
    margin-right: 8px;
  }

  .nav {
    padding: 8px;
  }

  .cancelledOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    z-index: 9;
    top: 0;
    left: 0;
    padding: inherit;
  }

  .cancelContent {
    background-color: $white;
    height: max-content;
    margin-top: 100px;
    max-width: 600px;
    padding: 32px;
  }

  .submitNow {
    margin: 0 8px;
  }
}
