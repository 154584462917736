@import "styles/colours.scss";
@import "styles/ant_component_overrides/ant_tag_multi_select.scss";

.keywordsWrapper {
  gap: 24px;
  display: flex;
  flex-direction: column;
  .keywordsLabel {
    font-size: 16px;
    line-height: 22px;
    color: $dark-blue;
    display: block;
    margin-bottom: 4px;
  }
  .hintLabel {
    margin-bottom: 16px;
  }
  p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 10px;
    color: $grey;
  }
  .select {
    @include multiselect-tag-style-input($blue);
  }
}

.keywordNoEmphasis {
  font-weight: normal;
  padding: 0 0.3em;
}

.hintIcon {
  margin-left: 8px;
  vertical-align: -0.125em !important; // ant is insisting on making icons in Form.Items top-aligned
  // this value is copied from the default for ant icons
}

.tag {
  color: $text-primary !important; // black tag color from theme gets overriden if using a custom color
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
}
