@import "styles/colours.scss";

.footer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.deleteButton {
  background-color: $red500;
  color: $white;
  border-color: $red500;

  &:hover,
  &:focus,
  &:active {
    background-color: $red500 !important;
    color: $white !important;
    border-color: $red500 !important;
  }
}
