@import "../../form_components/Inputs.module.scss";
@import "./SharedFilterGroups.module.scss";

.inputContainer {
  @extend .flexColumn;
}

.filterSection {
  @extend .filterSection;
}

.valueFields {
  display: flex;
  gap: 4px;
  align-items: center;
}

.valueInput {
  width: 100%;
}
