@import "styles/colours.scss";

.supplierProfileContent {
  margin: 16px 24px;
  padding: 0;
}

.noPaddingBottom {
  padding-bottom: 0;
}

.menuTabs {
  font-size: 14px;
  font-weight: 500;
  border-bottom: none;
  color: $dark-grey;
  margin-top: 24px;

  .menuTabItem {
    padding: 0px 16px;
  }
}
