.tooltip {
  max-width: 350px;
}

.buyerContainer {
  display: flex;
  max-width: 100%;
  padding-bottom: 8px;
  overflow-x: hidden;

  &.multiLine {
    flex-wrap: wrap;

    .categoriesContainer {
      width: 100%;
    }
  }
}
