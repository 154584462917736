@import "../../styles/colours.scss";

.container {
  padding: 16px;
  border: 1px solid $light-grey;
  border-radius: 8px;
  background-color: white;

  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: auto;
  column-gap: 16px;
}

.buyerName {
  font-size: 18px;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $dark-blue;
}

.profileLink {
  font-weight: 700;
  white-space: nowrap;
}

.logo {
  grid-row: 1 / 3;
  height: 60px;

  img {
    width: 60px;
    height: 60px;
  }

  i svg {
    width: 60px;
    height: 60px;
  }
}
