@import "styles/colours.scss";

.cpvCodesContainer {
  p {
    line-height: 18px;
    font-size: 12px;
  }
}

.cpvCodesNote {
  margin-bottom: 0;
  margin-top: 8px;
}

.settingsLabel {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
  color: $dark-blue;
  display: inline-block;

  i {
    margin-left: 8px;
  }
}
