@import "styles/colours.scss";
@import "styles/fonts.scss";

.container {
  padding-top: 32px;
}

.body {
  padding: 64px;
  height: 405px;
  background: linear-gradient(260.91deg, #785dc1 0.09%, #4732a8 110.51%);
  border-radius: 8px;
  color: white;
  width: 100%;
  display: flex;

  h2 {
    color: white;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
  }

  .title {
    color: white;
    font-weight: 700;
    line-height: 67px;
    font-size: 56px;
    white-space: pre-wrap;
    height: 204px;
    overflow: hidden;
    overflow-wrap: anywhere;
  }

  h3 {
    color: white;
    font-weight: 400;
  }
}

.geoImage {
  margin-right: -64px;
  margin-top: -219px;
  width: 438px;
  height: 661px;
}

.headerText {
  min-width: 61%;

  .reportDetails {
    font-size: 1.17em;
    font-weight: 400;

    .separator {
      font-weight: bold;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}
