@import "styles/constants.scss";

.page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  // change to 100% once vertical nav is implemented
  height: calc(100vh - $previewHeaderHeight - $topbarHeight);
  width: 100%;
}

.tempViewer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
