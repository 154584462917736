@import "styles/colours.scss";

.suggestedSection {
  padding-bottom: 24px;

  .suggestedLabel {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $text-primary-v2;
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    .loadingButton {
      width: 114px;
    }
  }

  .keywordSuggestion {
    background-color: $grey50;
    border-radius: 4px;
    padding: 0 8px;
    height: 32px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $text-primary-v2;

    &:hover,
    &:focus,
    &:active {
      border-color: $sysTextDefault !important;
      color: $sysTextDefault !important;
    }
  }

  .headerRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    .refreshSection {
      margin-left: auto;
    }

    .description {
      margin-bottom: 16px;
    }
  }
}
