@import "styles/colours.scss";

.dropdownArrow {
  font-size: 12px;
}

.otherButtonContainer {
  display: flex;
  gap: 8px;
  justify-content: right;

  .moreInfoDropdown {
    background-color: $white;
    padding: 5px 12px 5px 12px;
    color: $dark-grey;
    font-weight: 400;
    border-radius: 4px;
    font-size: 14x;

    &:hover {
      color: $link-blue-default;
      border-color: $link-blue-default;
    }
  }

  .dropdownArrow {
    font-size: 12px;
  }
}
