@import "styles/colours";

.optionContainer {
  padding: 8px 0px;
  border-radius: 8px;
}

.radioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  padding: 0px 8px;
}

.radioContainer:hover {
  background-color: $sysBackgroundAlternative;
}

.optionNameContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: normal;
  min-width: 100%;
}

.optionName {
  font-weight: bold;
}

.defaultTag {
  background-color: $sysPrimarySubtle;
  color: $sysPrimaryHover;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 0px 4px;
  gap: 8px;
}
