@import "styles/colours.scss";

.filtersHeader {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  border-bottom: 1px solid $grey50;
  width: 440px;
  overflow-y: auto;
}
