@import "styles/colours.scss";

.toolTip {
  position: absolute;
  box-shadow: 0px 2px 8px 0px #0000001f;
  border: 1px solid $light-grey2;
  border-radius: 4px;
  background-color: $white;
  padding: 16px;
}

.toolTipWithClick {
  @extend .toolTip;
  cursor: pointer;
}

.toolTipItem {
  position: relative;
  padding-left: 8px;
  margin-left: 0;
}

.toolTipLine {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #ccc;
  border-radius: 5px;
}

.toolTipContent {
  display: grid;
  gap: 16px;
}

.toolTipTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: $seo-dark-grey;
}

.value {
  font-size: 14px;
  line-height: 20px;
  color: $v2-dark-blue;
  font-weight: 700;
}

.clickMessage {
  font-size: 14px;
  color: $text-primary-v2;
}
