@import "styles/colours";

.pageWrapper {
  background-color: $white;
  height: 100%;
  width: 100%;
  padding: 40px 40px;
}

.tabs {
  row-gap: 10px;
}

.pageTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.04em;
  margin-bottom: 5px;
}

.pageDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 25px;
}
