@import "styles/tag_helpers.module";

.filtersContainer {
  display: flex;
  gap: 8px;
}

.keywordTag {
  @extend .nonTagCellTag, .keywordMatchTag;
  white-space: nowrap;
  margin: 4px 0 !important;
}

.clearFilters {
  font-size: 14px;
}

.searchInput {
  width: 240px;

  .searchIcon {
    color: $colorPrimary;
  }
}
