@import "../../styles/colours";
@import "styles/media_queries.scss";

.overlay {
  position: absolute;
  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  // This is to make sure the dropdown can get as large as possible if there are many signals
  // But never below the bottom of the screen
  max-height: 400px;
  min-width: 220px;
  background-color: $white;
  overflow-y: scroll;
}

.btn {
  display: flex;
  align-items: center;
}
