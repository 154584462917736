@import "styles/tag_helpers.module";
@import "styles/colours.scss";

.frameworkColumnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.oneLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.frameworkTitle {
  @extend .oneLine;

  &:hover {
    text-decoration: none;
  }
}

.titleText {
  font-weight: bold;
}

.tooltip {
  max-width: 350px;
}

.processTypeCell {
  @extend .oneLine;
  padding: 1px 8px;
  border-radius: 4px;
  background-color: #f7f7f8;
  overflow: hidden;
  max-width: fit-content;
  border: 1px solid $grey50;
}
