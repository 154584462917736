@import "styles/colours.scss";

.suggestedSection {
  padding-bottom: 0px;

  .suggestedLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $dark-blue;
    margin-bottom: 4px;
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  .keywordSuggestion {
    background-color: $light-grey;
    border-radius: 2px;
    border-color: $sysBorderPrimary;
    padding: 0 8px;
    height: 26px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: $dark-grey;

    &:hover,
    &:focus,
    &:active {
      border-color: $sysTextDefault !important;
      color: $sysTextDefault !important;
    }
  }

  .headerRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;

    .refreshSection {
      margin-left: auto;
    }
  }
}

.generatingButton {
  height: 26px;
  font-weight: 700;
  border-radius: 1px;
  border-color: $sysBorderPrimary;
  background-color: $sysBackgroundDefault;
  border-style: dashed;
  color: $dark-grey;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 20px;
  color: $sysTextInactive;
}

.spinner {
  padding-right: 4px;
  color: $sysTextInactive;
}
