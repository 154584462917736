@import "styles/tag_helpers.module.scss";
@import "styles/colours.scss";

@mixin multiselect-tag-style-input($tag-colour: $blue, $font-weight: 700) {
  li:global(.ant-select-selection__choice) {
    background-color: $tag-colour;
    border-style: none;
    font-size: 12px;
    line-height: 18px;
    height: 26px;
    display: flex;
    align-items: center;
    font-weight: $font-weight;
    margin-top: 0;
    margin-right: 8px;
    margin-bottom: 8px;
    color: $dark-grey;

    // the title attribute
    &[title="warning"] {
      @include warning-tag;
    }

    &[title="loading"] {
      @include loading-tag;
    }

    i:global(.ant-icon) {
      color: $dark-grey;
    }
  }

  li:global(.ant-select-search.ant-select-search--inline) {
    margin-top: 0;
    font-size: 14px;
    line-height: 18px;
    height: 26px;
    :global(.ant-select-search__field__wrap) {
      line-height: 18px;
    }
  }

  :global(.ant-select-selection--multiple) {
    padding: 8px;
    min-height: 42px;
  }
  :global(.ant-select-selection--multiple) :global(.ant-select-selection__rendered) {
    @include no-scrollbar;
    line-height: 26px;
    margin: 0;
    margin-bottom: -8px; // compensate for tag margin bottom
    ul {
      float: left; // Because ant floats the li we need to do the same to ul to give it height and actually wrap its children

      li {
        margin-top: 0px;
      }
    }
  }
  :global(.ant-select-selection__placeholder) {
    font-size: 14px;
    line-height: 26px;
    top: 0px;
    margin: 0;
    height: 100%;
  }
}
