@import "styles/colours.scss";
@import "styles/constants.scss";

.previewWrapper {
  display: flex;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-right: 1px solid $grey50;
  height: 100vh;
}

.closeIcon {
  color: $colorPrimaryText;
}

.controlsBtn {
  border-color: $grey50;
}

.previewContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
}

.container {
  display: flex;
  justify-content: center;
}

.columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;

  --toolbar-width: 440px;
}

.error {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.viewerContainer {
  height: calc(100vh - $previewHeaderHeight);
}

.toolbarContainer {
  height: calc(100vh - $previewHeaderHeight);
}
