.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;

  h3 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  p {
    font-size: 12px;
    color: #4f5a6f;
    max-width: 300px;
    margin-bottom: 0;
  }
}
