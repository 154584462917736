@import "styles/colours";

.label {
  margin-left: 0px;
}

.options {
  list-style: square;
  display: none;
  max-height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.0001em $light-grey;
  box-shadow:
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 100%;
  top: calc(100% + 4px);
  background-color: $white;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.showOptions {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 8px;
  margin-left: 8px;
  border-radius: 4px;

  &:hover {
    background-color: $sysPrimarySubtle;
    cursor: pointer;
  }
}
.typeAndPressEnter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 8px 16px;
  color: $sysTextPlaceholder;
  background-color: $sysBackgroundInactive;
  border-bottom: 1px solid $grey50;
}

.signalCount {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: $white;
  color: $sysTextSecondary;
}

.selectAll {
  @extend .signalCount;

  &:hover {
    background-color: $sysPrimarySubtle;
    cursor: pointer;
  }
}

.divider {
  margin: 8px;
}

.icon {
  margin-right: 4px;
  color: $colorPrimary;
}

.smallText {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
}

.keywordOptions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
}
