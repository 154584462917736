@import "styles/colours.scss";

.infoButton {
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
}

.cpvFilterContainer {
  margin-top: 22px;
}

.keywordsNullCheckbox {
  margin-top: 16px;
}

.settingsLabel {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
  color: $dark-blue;
  display: inline-block;

  i {
    margin-left: 8px;
  }
}
