@import "styles/colours.scss";
@import "styles/media_queries.scss";

$card-width: 285px;

.integrations {
  margin: 16px;
}

.pageTitle {
  margin-bottom: 8px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.subtitle {
  margin-bottom: 24px;

  span {
    font-weight: 700;
  }
}

.connectedConfirmation {
  background-color: $success-green;
  padding: 16px;
  text-align: center;
  width: $card-width;
  color: $white;
  border-radius: 4px 4px 0 0;

  i {
    margin-right: 4px;
  }
}

.providerCard {
  background-color: $white;
  width: $card-width;
  border: 2px solid $light-grey2;
  padding: 40px 32px 24px;

  &.connected {
    border-color: $success-green;
  }

  svg {
    height: 38px;
    width: 38px;
  }

  i {
    margin-bottom: 16px;
  }
}

.cardTitle {
  font-size: 16px;
  line-height: 24px;
  color: $dark-grey;
  margin-bottom: 11px;
  font-weight: 700;
}

.cardDescription {
  font-size: 14px;
  line-height: 20px;
  color: $grey;
  margin-bottom: 16px;
}

.marketplaceWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($card-width, min-content));
  grid-gap: 24px;
  align-items: end;
  margin-top: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.learnMore {
  display: flex;
  align-items: center;
  gap: 8px;
  background: $colorPrimary;
  color: $white;
  border-color: $white;
}

.icon {
  height: 16px;
  width: 16px;
}

.downIcon {
  padding: 0;
  margin-left: 5px;
  margin-right: -3px;
  margin-top: 4px;
  size: 12px;

  svg {
    height: 12px;
    width: 12px;
  }
}
