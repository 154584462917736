.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filterBar {
  display: flex;
  gap: 8px;
}

.textSearch {
  width: 240px;
}
