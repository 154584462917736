@import "styles/colours.scss";

.container {
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.icon {
  padding-right: 4px;
  padding-left: 4px;
}

em {
  padding: 0 0.3em;
}

.checkboxButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
