@import "styles/colours";

.tabTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.04em;
  margin-bottom: 5px;
}

.tabDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  padding-bottom: 20px;
  opacity: 68%;
}

.card {
  padding: 24px;
  border-radius: 4px;
  border: 1px solid $grey50;
  margin-bottom: 20px;
}

.cardTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.04em;
  margin-bottom: 5px;
}

.cardDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  opacity: 68%;
}

.cardHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.externalLink {
  color: $colorPrimary;
}

.icon {
  height: 13px;
  width: 13px;
  color: $colorPrimary;
}

.customAlert {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid $grey50;
  justify-content: space-between;
}

.alertName {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.alertDescription {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  opacity: 68%;
}

.actionButtons {
  display: flex;
  column-gap: 8px;
}

.customAlertsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.allAlertsActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 16px;
}

.searchInput {
  width: 20%;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.emptyStateTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.04em;
}

.emptyStateDescription {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  width: 262px;
  text-align: center;
  margin-bottom: 10px;
}
