@import "styles/colours.scss";

.cellPopoverList {
  max-height: 400px;
  overflow: auto;
}

.popoverTrigger {
  color: $link-blue-default;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
}
