@import "styles/colours";

.scrollableDiv {
  height: 320px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.optionsHeader {
  position: sticky;
  background-color: $white;
  width: 100%;
  z-index: 100;
  top: 0;
}

.labelAndIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  gap: 8px;
}

.labelAndIcon:hover {
  background-color: $colorPrimaryBg;
  border-radius: 4px;
}

.label {
  margin-left: 4px;
}

.divider {
  margin: 8px 0px;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tag {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 300px;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
