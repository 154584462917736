@import "styles/colours.scss";

.titleColumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.title {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}

.documentIcon {
  flex-shrink: 0;
}

.paywallStar {
  flex-shrink: 0;
}

.pagination {
  display: flex;
  margin: 16px 0px;
  justify-content: center;
}

.dateCell {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 100px;
}
