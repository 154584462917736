@import "styles/colours.scss";

.savedViewsBar {
  display: flex;
  align-items: center;
  justify-content: center;

  .quickSelectMenu {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 16px;
    border-bottom: none;
    margin: 0px;
    margin-bottom: 8px;
    padding: 0px;
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

.allViewsItem {
  position: relative;
  align-self: center;
  color: $text-primary-v2;
  margin: 0px;
  padding: 16px;
  background-color: $border-grey;
  border: 2px solid $border-grey;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  min-width: 240px;
  max-width: 320px;
  height: 100px;

  .allViewsContent {
    font-family: inherit;

    .fileSearchIcon {
      color: $link-blue-default;
      size: 18px;
      margin-right: 8px;
    }
  }

  &:active,
  &:hover {
    cursor: pointer;
    background-color: $light-grey2;
    border: 2px solid $light-grey2;
  }
}
