@import "styles/colours.scss";
@import "styles/constants.scss";

.pageBackground {
  width: 100%;
  min-height: 100%;
  height: fit-content;
  background-color: $app-bg-grey;
  display: flex;
  flex-direction: column;
}

.whiteBackground {
  @extend .pageBackground;
  background-color: $white;
}

.subscriptionPage {
  max-width: calc(100vw - $sidebarWidthExpanded);
}
