@import "styles/colours";

.container {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  button {
    align-self: flex-start;
  }
}

.betaWarning {
  margin-bottom: 24px;
}

.viewButtonContainer {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}

.groupByContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  span {
    font-size: 16px;
  }
}

.tabs {
  width: calc(100% - 95px);
}

.section {
  padding-bottom: 0px;
}

.filterButton {
  margin-right: 12px;
}
