@import "styles/colours.scss";
@import "styles/fonts";
@import "styles/layouts";

.buyerListHeader {
  padding-bottom: 0px;
}

.cell {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.nameCell {
  @extend .cell;
  justify-content: space-between;
  line-height: 32px;

  .actionsDropdownText {
    padding-right: 4px;
  }
}

.ownerCell {
  @extend .cell;
  min-width: 160px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.searchInput {
  width: 320px;
}

.searchContainer {
  margin: $default-page-border;
  margin-bottom: 0;
}

.titleAndBtn {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  justify-content: space-between;
  margin-bottom: 8px;
}

.menuTabs {
  font-size: 14px;
  font-weight: 600;
  color: $dark-grey;
  border-bottom: none;

  .menuTabItem {
    padding: 0;
    margin-right: 24px;
  }
}

.emptyState {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
}
