.modalHeader {
  // put export button on the right of the grid
  display: grid; //have the third div span the 2nd column
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content max-content;
  grid-template-areas: "title export" "title export";
  align-items: center;
  gap: 4px;

  .export {
    grid-area: export;
  }

  h4 {
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.modal {
  display: grid;
  gap: 16px;
}

.table {
  width: 952px;
}
