@import "styles/colours.scss";

.linksContainer {
  display: flex;
  align-items: center;

  .signInLink {
    color: $dark-grey;
    line-height: 20px;
    padding: 8px 8px;
    align-items: center;
    margin-right: 15px;

    a {
      color: $seo-dark-green;
    }
  }

  .signUpButton {
    margin-bottom: 12px;
  }
}
