@import "styles/colours.scss";

.viewTable {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.searchInput {
  width: 35%;
}

.ownerCell {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.viewNameCell {
  display: flex;
  justify-content: space-between;
}

.viewNameActions {
  display: flex;
  gap: 8px;
}

.viewNameText {
  color: $colorPrimaryText;
  cursor: pointer;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
}
