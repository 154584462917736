@import "styles/colours.scss";

.integrationsNotifications {
  h2 {
    font-size: 14px;
    line-height: 22px;
    color: $dark-grey;
  }
}

.label {
  font-size: 14px;
  line-height: 22px;
  color: $dark-blue;
  margin-bottom: 8px;
  display: block;
}

.formItem {
  margin-bottom: 16px;
}

.inlineFormItem {
  .label {
    margin: 0 16px 0 0;
  }
  display: flex;
  align-items: center;
}

.subscriptionSelector {
  padding: 16px;
  display: grid;
  row-gap: 6px;
  column-gap: 16px;
  grid-template-columns: 1fr max-content max-content;
  grid-template-rows: repeat(4, max-content);
  border: 1px solid $legacy-border-grey;
  border-radius: 4px;
  color: $grey;
  font-size: 12px;
  line-height: 18px;
  &:hover {
    color: $grey;
  }
}

.edit {
  grid-row: 1/-1;
  grid-column: 2;
  align-self: center;
  color: $link-blue-default;
  &:hover {
    color: $link-blue-hover;
  }
}

.deleteIcon {
  grid-row: 1/-1;
  grid-column: 3;
  align-self: center;
}

.listsAndViews {
  p {
    margin: 0;
  }
}

.noSubscriptionsMessage {
  font-size: 14px;
  line-height: 22px;
  color: $grey;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
