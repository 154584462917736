@import "styles/colours.scss";

.feedSettingsInputs {
  display: grid;
  gap: 16px;
  padding: 16px 0;

  .settingsLabel {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    color: $dark-blue;
    display: inline-block;

    i {
      margin-left: 8px;
    }
  }
}

.upgradeContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
}

.upgradeTxt {
  color: $colorTextSecondary;
  font-size: 14px;
  margin: 0;
}

.learnMoreBtn {
  width: fit-content;
}
