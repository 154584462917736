@import "styles/colours.scss";

.findDuplicatesPage {
  background-color: $white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 185px minmax(0, 1fr);
  grid-gap: 8px;
  height: 100%;

  .searchContainer {
    padding: 8px;
  }

  .anchorContainer {
    padding: 8px;
  }

  .qualificationButtons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 4px;
    width: min-content;

    button {
      font-size: 12px;
    }
  }

  .unsureButton {
    border-color: $red;
    color: $red;
  }

  .anchorLabel {
    font-size: 14px;
  }
}
