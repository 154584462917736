@import "styles/colours.scss";

.teamsPageContainer {
  height: 100%;
}

.teamMembersContainer {
  background-color: $white;
  padding: 24px;
  height: 100%;
}

.myTeam {
  display: grid;
  gap: 16px;
}

.title {
  font-size: 20px;
  line-height: 24px;
  color: $dark-blue;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  button {
    align-items: center;
    font-size: 14px;
    line-height: 22px;
    height: 100%;
  }
}

.editTeam {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
}

.input {
  width: 320px;
}

.filters {
  display: flex;
  gap: 12px;
}

.select {
  width: 200px;
}

.addTeamsMessage {
  margin: 16px 0;
}

.usersCount {
  margin: 16px 0;
}

.nameCell {
  display: grid;
  grid-template-areas:
    "initials name"
    "initials email";
  grid-template-columns: 32px 1fr;
  grid-column-gap: 12px;
}

.userInitials {
  grid-area: initials;
  align-self: center;
}

.userName {
  grid-area: name;
}

.userEmail {
  grid-area: email;
}
