@import "styles/media_queries.scss";
@import "styles/colours.scss";

.emailVerificationPage {
  display: flex;
  width: 100%;
  justify-content: center;
  .innerWrapper {
    text-align: center;
    max-width: 600px;
    margin-top: 10vh;
    @media (min-width: map-get($breakpoints, xxl)) {
      margin-top: 20vh;
    }
  }

  .primaryHeading {
    font-size: 46px;
    font-weight: 700;
    color: $dark-blue;
  }

  .secondaryHeading {
    font-weight: 400;
    font-size: 46px;
    margin-top: 0;
    color: $dark-blue;
  }

  .actionItemsContainer {
    margin-top: 60px;
  }

  .actionItems {
    display: block;
    margin: 0 auto 15px;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }

  .requestEmailContainer {
    margin-top: 20px;
  }

  .requestInputForm {
    width: 60%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .requestEmailInput {
    margin-bottom: 20px;
    height: 52px;
    font-size: 18px;
    line-height: 36px;
    color: $placeholder-grey;
  }
  .requestEmailButton {
    height: 42px;
  }
}
.stotlesEmailLink {
  font-weight: 700;
}
