@import "styles/media_queries.scss";
@import "styles/colours.scss";

.backButtonContainer {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 96px;
  padding-bottom: 32px;
  padding-left: 40px;
  padding-right: 40px;
}

.backButton {
  color: $dark-blue !important;
}

.authenticationFailurePage {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 396px;
}

.authenticationFailureContainer {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
}

.actionItemsContainer {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  border: 0px;
  font-weight: 400;
  line-height: 22px;
  color: $text-primary;
  font-size: 14px;
  gap: 8px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: row;
}

.headerTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
}

.description {
  p {
    color: $grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $text-secondary;
  }
}

.switchToWorkEmailButton {
  background-color: $colorPrimary;
}
