@import "styles/colours.scss";

.buyerCleaningPage {
  height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  width: 100vw;

  .pageContent {
    position: relative;
    padding: 16px;
    background-color: $app-bg-grey;
    height: calc(100% - 57px);
  }

  .nav {
    padding: 8px;
  }

  .submitNow {
    margin: 0 8px;
  }
}
