@import "styles/colours.scss";
@import "styles/fonts.scss";
@import "styles/media_queries.scss";
@import "styles/ant_utils.scss";

//TODO: Consider using a grid
.createAccountContainer {
  display: flex;
  justify-content: center;
  min-width: 100vw;

  .createAccount {
    display: flex;
    width: 100%;
    max-width: 1512px;
    height: 100%;
    max-height: 926px;

    .leftPane,
    .rightPane {
      position: relative;
      @media (max-width: map-get($breakpoints, lg)) {
        width: 100%;
      }
    }

    .leftPane {
      min-width: 50%;
      max-width: 564px;
      padding: 56px 96px;
      // total element width adds up to 756px
      font-size: 14px;

      p {
        font-size: 14px;
        color: $text-secondary;
      }
    }

    .leftPaneInner {
      overflow-y: auto;
      max-height: calc(100vh - 64px);
      @include no-scrollbar;
    }

    .progressBar {
      margin-bottom: 56px;
      max-width: 200px;
    }

    .headerTitle {
      h1 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }

    .header {
      margin-bottom: 40px;
    }

    .rightPane {
      min-width: 50%;
      max-width: 660px;
      padding: 48px;
      // total element width adds up to 756px
      max-height: calc(100vh - 65px);
      @include no-scrollbar;
      @media (max-width: map-get($breakpoints, lg)) {
        display: none;
      }

      .rightImageContainer {
        max-height: 830px;
        background-image: url("../../../assets/images/onboarding/authorisation_background.svg");
        background-position-y: 30%;
        background-size: cover;
        background-size: cover;
        height: calc(100vh - 161px);
        border-radius: 16px;
        display: flex;
        align-items: flex-end;

        .rightText {
          flex-direction: column;
          padding-bottom: 48px;

          .workdayLogo {
            width: 138px;
            height: 55px;
            margin-left: 48px;
            margin-bottom: 16px;
          }

          h1,
          p {
            color: white;
            margin-left: 48px;
            margin-right: 48px;
          }

          h1 {
            line-height: 32px;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 16px;
          }

          p {
            line-height: 22px;
            font-weight: 500px;
            font-size: 14px;
            margin-bottom: 48px;
          }

          .jobTitle {
            line-height: 20px;
            font-weight: 400px;
            font-size: 12px;
            margin-bottom: 0px;
            margin-bottom: 0px;
          }

          .employeeName {
            margin-bottom: 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
