@import "styles/colours.scss";

.setupPage {
  text-align: center;
  padding-top: 40px;
  background: $white;
}

.integrationLogos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $text-primary-v2;
}

.stotlesHeaderLogo {
  width: 80px;
  height: 80px;
  border: 1px solid $light-grey2;
  border-radius: 4px;
  padding: 18px;
}

.navigation {
  margin-top: 32px;
  .backButton {
    margin-right: 8px;
  }
}

.providerHeaderLogo {
  border: 1px solid $light-grey2;
  border-radius: 4px;
  padding: 18px;
  svg {
    width: 38px;
    height: 38px;
  }
}

.setupForm {
  background: $white;
  border: 1px solid $pagination-border;
  border-radius: 12px;
  width: 834px;
  display: inline-block;
  padding-top: 38px;
  margin-bottom: 200px;
  margin-top: 32px;
}

.setupFormHeader {
  border-bottom: 1px solid $pagination-border;
  padding-bottom: 38px;
  .heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
}

.setupBody {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fieldHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.inlineProviderLogo {
  border: 1px solid #ebebeb;
  border-radius: 4px;

  i {
    width: 24px;
    height: 24px;
    vertical-align: inherit;
    line-height: inherit;
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

.inlineStotlesLogo {
  margin-left: 4px;
  margin-right: 4px;
  padding: 4px;
  width: 24px;
  height: 24px;
  vertical-align: text-top;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.fieldTable {
  width: 400px;
  border-collapse: separate;
  border-spacing: 0 24px;

  tr {
    border-spacing: 2px;

    .removeField {
      visibility: hidden;
    }

    &:hover .removeField {
      visibility: visible;
    }
  }

  tr td:first-child,
  tr th:first-child {
    width: 240px;
    text-align: left;
  }
}

.fieldSelect {
  width: 100%;
}

.setupFooter {
  border-top: 1px solid $pagination-border;
  padding: 30px;
  display: flex;
  justify-content: flex-end;
}
