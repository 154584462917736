.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalTitle {
  margin: 0;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.modalSubtitle {
  margin: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
