@import "styles/colours.scss";

.popoverTrigger {
  color: $sysPrimaryDefault;
  font-weight: 700;
  cursor: pointer;
  display: inline-block;
}

.cellPopoverList {
  max-height: 400px;
  overflow: auto;
  // This is to offset the built in padding that's already in ant-inner-content
  // This way we avoid using another override
  margin: -12px 0 -12px;
}
