@import "styles/colours.scss";

.pageTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 8px 0px;
  color: $dark-blue;
}

.noResultsMessage {
  color: black;
  font-size: 14px;
  line-height: 20px;
}

.titleLink {
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}

.signalLatestActivity {
  margin-bottom: 0px;
  margin-top: 4px;
  color: $text-secondary;
}
