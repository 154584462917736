@import "styles/colours.scss";

.recordStageButtonContainer {
  margin: 0 -8px -8px 0;

  button {
    margin: 0 8px 8px 0;

    &:focus,
    &:active {
      border: 1px solid $placeholder-grey;
    }

    &.selected {
      border: 1px solid $link-blue-default;
    }

    // This is needed because otherwise the pop-up won't be triggered by the hover over the parent button
    .stage {
      pointer-events: all;
    }

    &:disabled {
      border: 1px solid $placeholder-grey;

      > * {
        color: $placeholder-grey;
      }
    }
  }

  &.small {
    button {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
