@import "styles/ant_component_overrides/ant_tag_multi_select.scss";
@import "styles/colours.scss";

.supplierTagMultiselect {
  &.partner {
    @include multiselect-tag-style-input($light-yellow);
  }
  &.competitor {
    @include multiselect-tag-style-input($light-red);
  }
}

.tag {
  color: $text-primary !important; // black tag color from theme gets overriden if using a custom color
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
}
