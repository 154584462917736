@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.title {
  margin: 0;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
