@import "styles/colours.scss";

.downloadIcon {
  color: $colorPrimary;
}

.paywallButton {
  padding-left: 8px;
  padding-right: 12px;
}
