.userSelect {
  width: 100%;
}

.roleList {
  margin-top: 16px;
}

.roleSelect {
  min-width: 86px;

  & :global(.ant-select-selection) {
    border: 0;
  }
}

:global(.ant-select-disabled .ant-select-selection) {
  background-color: white;
}
