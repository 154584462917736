@import "styles/colours.scss";

.stageTooltip {
  margin-bottom: 8px;
}

.frameworkStage {
  // This is needed because otherwise the pop-up won't be triggered by the hover over the parent button
  pointer-events: all;
  // Necessary to override the default styling when it's a button
  display: flex !important;
  align-items: center;
  gap: 8px;
}
