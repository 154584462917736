@import "styles/colours.scss";

.savedViewItem,
.savedViewActive {
  color: $text-primary-v2;
  background-color: $white;
  position: relative;
  padding: 16px;
  margin: 0px;
  border: 1.5px solid $border-grey;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  min-width: 240px;
  max-width: 320px;
  max-height: 100px;
  text-align: left;

  // View pane has different look when it's hovered over.
  // Colour changes and pin icon and handle icon appear
  &:hover {
    color: $text-primary-v2;
    background-color: $colorPrimaryBg;
    border: 1.5px solid $colorPrimary;
    cursor: pointer;

    .pinnedIcon {
      display: inline-block !important;
    }

    .pinnedIcon {
      color: $colorPrimary;
    }

    .holderContainer {
      display: inline;
    }
  }

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .pinnedIcon {
    top: 6%;
    right: 2%;
    position: absolute;
    font-size: 16px;
    display: none !important;
  }

  .pinnedIcon {
    color: $colorPrimary;

    &:hover {
      color: $colorPrimary;
      background: #1890ff26;
    }
  }

  .holderContainer {
    display: none;
    position: absolute;
    left: 0px;
    top: 40%;

    .holderIcon {
      font-size: 24px;
      position: relative;
      right: 5%;
      top: 10%;
      padding-right: 4px;
      cursor: move;
      /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      color: $colorPrimary;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }

  .paneViewTitle {
    padding: 0px 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .descriptionContainer {
    padding: 8px;
    font-size: 12px;
    font-weight: 400;

    .description {
      color: $text-secondary;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .placeholderDesc {
      color: $placeholder-grey;
    }
  }
}

.savedViewActive {
  color: $white;
  background-color: $colorPrimary;
  border: 1.5px solid $colorPrimary;
  cursor: pointer;

  .pinnedIcon {
    color: $white;
  }

  // Overwrite the colour styling of above for hover state
  &:hover {
    color: $white;
    background-color: $colorPrimaryHover;

    .pinnedIcon {
      color: $white;
    }
  }

  .holderContainer {
    .holderIcon {
      color: $white;
    }
  }

  .descriptionContainer {
    .description {
      color: $white;
    }

    .placeholderDesc {
      color: $light-grey;
    }
  }
}
