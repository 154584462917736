.viewsHeaderContainer {
  width: 100%;
}

.viewsHeaderRow {
  display: flex;

  .viewsHeaderText {
    min-width: 80%;
  }

  .viewsHeaderOptions {
    min-width: 20%;
  }
}

.pinnedViewsList {
  padding-top: 16px;
}
