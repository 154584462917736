@import "styles/colours.scss";

.modal {
  min-width: calc(75vw - 16px);
}

.qaPairs {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 600px;
  padding: 16px;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.questionInput {
  padding: 8px 16px;
  border-radius: 24px;
  width: 100%;
}

.inputContainer {
  display: flex;
  align-items: center;
}

.disclaimer {
  text-align: center;
  font-size: 12px;
  color: grey;
  padding-top: 8px;
  padding-bottom: 8px;
}

.sources {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 40px;
  max-width: fit-content;
}
