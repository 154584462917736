@import "styles/colours";

.container {
  display: flex;
  max-width: 1280px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-start;
  box-sizing: border-box;
  padding-top: 100px;
  gap: 32px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 328px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 600px;
}

.portalLink {
  font-weight: 700;
}

.uploadedDocuments {
  margin-top: 32px;
  margin-bottom: 8px;
}

.stepTitle {
  display: flex;
  align-items: center;
}

.tooltipIcon {
  color: $grey700;
  margin-right: 4px;
}

.generateBtn {
  margin-left: auto;
  margin-top: 64px;
}

.uploadedFile {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid $grey50;
  padding: 12px;
  width: 500px;
  margin-right: 12px;
}

.files {
  gap: 16px;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.file {
  display: flex;
  width: 550px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.errorContainer {
  color: $colorError;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
}

.errorText {
  margin-bottom: 8px;
}
