@import "styles/colours.scss";

.page {
  display: flex;
  justify-content: space-around;

  h1 {
    font-size: 40px;
    line-height: 1;
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    color: $colorText;
  }
}

.pageContent {
  max-width: 1000px;
  padding: 120px 20px;
  display: grid;
  gap: 20px;
}

.container {
  img {
    width: 100%;
  }
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 125px;
  row-gap: 50px;
  align-items: center;
}

.content {
  display: grid;
  gap: 20px;
  color: $colorTextTertiary;
}

.logo {
  height: 50px;
}

.contactMessage {
  color: $colorText;
}
