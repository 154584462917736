@import "styles/colours.scss";

.form {
  display: flex;
  gap: 15px;
  flex-direction: column;
  padding-top: 10px;
}

.add {
  color: $blue600;
  cursor: pointer;
  align-self: flex-start;
  padding: 0;
}

.button {
  align-self: flex-start;
  margin-top: 15px;
}

.files {
  padding: 20px;
  border-radius: 5px;
  background-color: $light-grey;

  p {
    font-weight: 700;
  }
}

.fileHeader {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.fileGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.remove {
  color: $red500;
}

.input {
  flex-grow: 1;
}

.formText {
  align-self: center;
  font-weight: 700;
  padding-top: 6px;
}
