.userAnswerRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 1em;
  text-align: left;

  .userQuestion {
    padding-left: 12px;
    .userHeader {
      font-weight: 600;
      margin: 0;
    }
  }
}

.ariAnswerRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 1em;
  text-align: left;

  .avatar {
    margin-left: 12px;
    margin-top: 7px;
    margin-right: 1em;
  }

  .ariAnswer {
    padding-left: 12px;

    p {
      text-align: left;
    }

    .ariHeader {
      font-weight: 600;
      margin: 0;
    }
  }
}

.sparkles {
  align-self: flex-start;
  padding-left: 5px;
  padding-right: 5px;
}
