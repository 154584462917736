@import "styles/colours";

.optionLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.optionsContainer {
  min-width: 100%;
}

.treeContainer {
  width: 100%;
}

.signalIcon {
  margin-left: 4px;
  margin-right: 4px;
  color: $colorPrimary;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
