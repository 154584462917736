@import "styles/colours";

.signalIcon {
  display: flex;
  margin-right: 8px;
  color: $colorPrimary;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
