.titleContainer {
  display: flex;
  justify-content: space-between;
}

.titleCell {
  display: grid;
}

.titleText {
  font-weight: bold;
  padding-bottom: 10px;
}

.tooltip {
  max-width: 350px;
}

.recordStatus {
  display: flex;

  & > div {
    margin-left: 12px;
    margin-right: 12px;
  }
}
