@import "styles/colours";
@import "styles/layouts";

.overlay {
  background-color: $white;
  padding: 16px;
}

.layout {
  padding: $default-page-border;
}
