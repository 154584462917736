.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
  }

  p {
    color: #4f5a6f;
    max-width: 280px;
    margin-bottom: 0;
  }
}

.actionBtn {
  margin-top: 8px;
}
