@import "styles/colours.scss";

.qualificationReportPage {
  flex: 1;
}

.header {
  display: flex;
  padding: 24px;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid $grey50;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}

.deleteButton {
  border: 1px solid $red500;

  &:hover {
    background-color: $red50;
  }
}

.headerActions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.extraPadding {
  padding-right: 8px;
}

.actionButton {
  &:hover {
    background-color: $blue50;
  }
}

.viewOnlyText {
  cursor: pointer;
  color: $sysPrimaryDefault;
}

.pageContent {
  display: flex;
  gap: 32px;
  padding-top: 0px;
}

.scorecardTitle {
  margin-bottom: 16px !important;
}

.scoreCardWrapper {
  display: flex;
  flex-direction: column;
  padding: 48px 32px;
  gap: 32px;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.noticeHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .titleContent {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border-bottom: 1px solid $grey50;
    width: -webkit-fill-available;
  }
}

.linkTitle {
  font-weight: 500;
  color: $sysTextSecondary;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100%;
}

.askAIBtn {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  padding-left: 8px;
  font-weight: 500 !important;
}

.buyerName {
  margin-bottom: 26px;
}

.disclaimerText {
  font-size: 12px;
  margin: 0;
}

.qaReportContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.menuItem {
  display: flex;
  gap: 8px;
  align-items: center;
}

@keyframes details-show {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
