@import "styles/colours.scss";

.tenderDocuments {
  width: 100%;
  height: 100vh;
  max-width: 500px;
  padding: 20px 24px;
  border-left: 1px solid $grey50;
  background-color: $white;
  z-index: 2;

  @media (max-width: 1400px) {
    max-width: 400px;
  }

  .item {
    padding: 8px;
    margin: 8px;
    background: $grey10;
    border: 1px solid $grey50;
    border-radius: 4px;
    color: $grey700;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-right: 6px;
      color: $grey700;
    }
  }
}

.title {
  margin-bottom: 16px;
}

.documentText {
  color: $grey900;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
