.toolbar {
  width: var(--toolbar-width); // set on .columns in DocumentDrawer.module.scss
  overflow: hidden;

  :global {
    .ant5-tabs-content-holder {
      display: flex;
      flex-direction: column;
    }

    .ant5-tabs-content {
      display: flex;
      flex: 1;
      height: 100%;
    }

    .ant5-tabs-tabpane-active {
      display: flex;
      flex-direction: column;
    }
  }
}
