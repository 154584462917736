.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  grid-template-rows: auto;
  column-gap: 24px;
  row-gap: 24px;
  padding: 0px 8px;
}

.card {
  max-height: 450px;
}

.coverImage {
  height: 276px;
  width: 100%;
  object-fit: fill;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  min-height: 93px;
  justify-content: space-between;
}

.description {
  overflow: hidden;
  max-height: 76px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cardActions {
  display: flex;
  justify-content: space-between;
}

.customIconButton {
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }
}
