@import "../filter_form/SharedFilterForm.module.scss";

.header {
  display: flex;
  padding-bottom: 16px;
  line-height: 24px;
  align-items: baseline;
}

.filterGroup {
  display: flex;
  flex-direction: column;
  gap: 18px;
  h3 {
    margin-bottom: 0px;
  }
}

.filterInput {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.formContent {
  @extend .formContent;
}
