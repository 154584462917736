@import "styles/colours.scss";
@import "styles/ant_utils.scss";

.feedSettings {
  display: grid;
  grid-template-rows: minmax(0, 1fr) max-content;
  height: 100%;
  .feedSettingsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 16px;
    gap: 16px;
  }
  // ---------- Left Panel: Search Inputs ----------
  .previewInputs {
    position: relative;

    .previewInputsInner {
      overflow-y: auto;
      max-height: calc(100vh - 128px);

      @include no-scrollbar;
    }

    .previewInputsInner[data-vertical-nav="true"] {
      max-height: calc(100vh - 64px);
    }
  }

  .submissionWrapper {
    background: $t-header-bg;
    box-shadow: 0 -5px 10px rgba(19, 35, 63, 0.1);
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .submissionLabel {
      font-size: 12px;
      color: $black;
    }

    .actionButtonWrapper {
      font-size: 12px;
      display: grid;
      grid-template-columns: max-content max-content;
      gap: 8px;
    }
  }

  // ---------- Right Panel: Search Outputs ----------

  .previewOutputs {
    max-height: calc(100vh - 160px);
    margin: 16px 0;
    position: relative;
  }
}
