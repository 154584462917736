.advancedSettings {
  background-color: transparent;
  border-style: none;

  .collapseItem {
    border-style: none;

    .hideLabel {
      font-size: 14px;
      line-height: 18px;
      vertical-align: sub;
    }
  }
  .arrowIcon {
    font-size: 10px;
    // normally ant does this with vertical align but we had to override the parent position
    margin: 0 0 -0.125em 2px;
  }

  .header {
    display: flex;
    height: 32px;
    justify-content: space-between;
  }
}
