@import "../../styles/colours";

.nameColumn {
  display: grid;
  grid-template-columns: 1fr max-content;
  line-height: 32px;
}

.buyerName {
  display: grid;
}

.titleLink {
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}
