@import "styles/colours.scss";

.aiQuestionItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20%;
  padding-right: 20%;
  width: 100%;
  padding-bottom: 48px;
}

.interactionRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;

  .feedbackButton {
    padding-top: 2px;
    border: 0;
    box-shadow: none;
  }
}

.iconSparkles {
  height: 16px;
  width: 16px;
}
