.content {
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.chart {
  height: 50vh;
  width: 100%;
}

.backToLink {
  display: flex;
  margin-bottom: 8px;
}
