@import "styles/colours.scss";

.savedViewItem,
.savedViewActive {
  color: $text-primary-v2;
  background-color: $white;
  position: relative;
  padding: 16px;
  margin: 0px;
  border: 1.5px solid $border-grey;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  width: 320px;
  height: 100px;
  text-align: left;

  // View pane has different look when it's hovered over.
  // Colour changes and pin icon and handle icon appear
  &:hover {
    color: $text-primary-v2;
    background-color: $colorPrimaryBg;
    border: 1.5px solid $colorPrimary;
    cursor: pointer;
  }

  .paneViewTitle {
    padding: 0px 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .descriptionContainer {
    padding: 8px;
    font-size: 12px;

    .description {
      color: $text-secondary;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.savedViewActive {
  color: $white;
  background-color: $colorPrimary;
  border: 1.5px solid $colorPrimary;
  cursor: pointer;

  // Overwrite the colour styling of above for hover state of views
  &:hover {
    color: $white;
    background-color: $colorPrimaryHover;
  }

  .descriptionContainer {
    .description {
      color: $white;
    }
  }
}

.savedViewDisabled {
  @extend .savedViewItem;
  color: $disabled-grey;
  background-color: $disabled-button-background;
  border-color: $disabled-button-border;
  cursor: not-allowed;

  &:hover {
    @extend .savedViewDisabled;
  }
}
