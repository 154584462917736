@import "styles/colours.scss";

.newChatSection {
  border-bottom: 1px solid $colorBorder;
  padding-top: 8px;
  padding-right: 8px;
  margin-right: 12px;

  .newChatButton {
    display: flex;
    justify-content: space-between;
    line-height: normal;
    color: $text-dark-blue;
    font-weight: 500;
    margin-bottom: 16px;

    .editIcon {
      line-height: normal;
    }
  }
}

.history {
  padding-right: 24px;
  overflow-y: auto;
  max-height: 85vh;

  h3 {
    font-size: 12px;
    color: $text-secondary;
    padding-top: 24px;
    padding-left: 16px;
    padding-bottom: 12px;
  }

  .historicalQuestionButton {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;

    .buttonContent {
      text-overflow: ellipsis;
      overflow: hidden;

      .wrappedText {
        -webkit-line-clamp: 1;
      }

      .fileIcon {
        padding-top: 4px;
        color: $text-dark-blue;
      }
    }
  }
}
