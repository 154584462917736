@import "styles/colours";

.inputField {
  flex-grow: 1;
  padding: 0px 8px;
  min-height: 32px;
}

.filterLabelDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 4px;
  min-height: 32px;

  &[data-label="false"] {
    min-height: 0;
  }
}

.clearButton {
  padding: 4px 0px;
  border: none;
  box-shadow: none;
  color: $colorPrimary;
}

.clearButton:hover {
  color: $colorPrimary;
}
