@import "styles/colours.scss";

.askAiModal {
  min-width: calc(100vw - 16px);
  top: 8px;
  margin-right: 8px;
  margin-left: 8px;

  .askAiPanel {
    display: flex;
    flex-direction: row;
    height: 91.1vh;

    .historicalQuestionSection {
      display: flex;
      flex-direction: column;
      width: 18vw;
    }

    .newQuestionSection {
      padding: 16px;

      .closeButton {
        margin-left: auto;
        padding-left: 8px;
        padding-right: 8px;
        border-color: $grey50;
      }

      display: flex;
      flex-direction: column;
      overflow-y: auto;
      width: 100%;
      background-color: white;
      border-radius: 8px;

      .header {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $colorText;

        .headerText {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 0;
          padding-bottom: 2em;

          .headerTitle {
            font-weight: 700;
            margin-bottom: 0;
          }

          .headerSubtitle {
            margin-bottom: 0;
          }
        }
      }

      .sparkles {
        grid-area: sparkles-icon;
        align-self: center;
        justify-self: center;
        padding-right: 1em;
      }

      .footer {
        margin-top: auto;
        padding-left: 208px;
        padding-right: 208px;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .inputContainer {
        .questionInput {
          padding-left: 24px;
          padding-right: 24px;
          padding-top: 16px;
          padding-bottom: 16px;
          border-radius: 56px;
        }

        .disclaimer {
          text-align: center;
          font-size: 12px;
          color: grey;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
  }
}
