@import "styles/colours.scss";

.backLink {
  margin-bottom: 8px;
}

.heading {
  color: $dark-blue;
  font-size: 24px;
  line-height: 32px;
  display: inline-block;
  font-weight: 700;
}

.pageSignal {
  margin-left: 8px;
  display: inline-block;
}

.noPaddingBottom {
  padding-bottom: 0;
}
