@import "styles/colours";

.keywordSelections {
  display: flex;
  align-items: flex-start;
  justify-items: center;
  width: 100%;
  max-height: 270px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding-top: 4px;
  padding-bottom: 8px;
  border-radius: 8px;
  background-color: $white;
  width: 100%;
}

.tag {
  display: flex;
  height: 30px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid $sysPrimarySubtleAlt;
  background-color: $colorPrimaryBg;
  align-items: center;
  overflow: hidden;
  margin-inline-end: 0px;
  padding: 8px 12px;
  gap: 6px;

  &:hover {
    cursor: default;
  }
}

.tagLabel {
  max-width: 500px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  color: $grey700;
}
