@import "styles/colours.scss";

.banner {
  background: $colorPrimary;
  display: flex;
  color: $white;
  border-radius: 4px;
  justify-content: space-between;
}

.addPadding {
  padding: 24px;
  padding-bottom: 0px;
}

.featureMessage {
  background: #4c7de3;
  border-radius: var(4px);
  color: $white;
  padding: 5px 12px;
  margin-bottom: 16px;
  line-height: 20px;
  width: 200px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.textSection {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message {
  margin-bottom: 24px;
}

.header {
  color: $white;
}

.heading {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: $white;
  margin-bottom: 8px;
}

.secondSection {
  width: 50%;
  background: radial-gradient(farthest-corner at 115% -170%, #95b6fb 70%, $colorPrimary 70%);
  padding: 32px;
  display: flex;
  justify-content: flex-end;
  border-radius: 4px;
}

.closeButton {
  color: $white;
}

.graphicSection {
  display: flex;
  justify-content: center;
}

.btnContainer {
  display: flex;
  align-items: center;
}
