@import "styles/colours.scss";

.progressBarContainer {
  max-width: 200px;
  margin-bottom: 56px;
}

.keywordButton {
  background-color: $sysInfoSubtle;
  border-color: $sysInfoSubtleAlt;
  border-radius: 4px;
  padding: 0 8px;
  height: 32px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: $sysTextDefault;
  &:hover,
  &:focus,
  &:active {
    border-color: $sysTextDefault !important;
    color: $sysTextDefault !important;
  }
}

.keywordInput {
  border-radius: 4px;
  border-color: $sysBorderPrimary;
  width: 320px;
}

.header {
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $sysTextDefault;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $sysTextSecondary;
    padding-bottom: 0px;
  }

  margin-bottom: 24px;
}

.selectKeywords {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  label {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $sysTextDefault;
    margin-bottom: 16px;
  }

  .inputRow {
    margin-bottom: 0px;
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    .loadingButton {
      width: 114px;
    }
  }
}

.keywordBarContainer {
  display: flex;
  flex-direction: row;

  .keywordBar {
    margin-right: 8px;
  }
}

.keywordHelperText {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 24px;
  color: $sysTextSecondary;
}

.finishContainer {
  position: sticky;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  padding-top: 24px;
  padding-bottom: 24px;

  .finishButtonDisabled {
    background-color: $grey10;
  }
}
