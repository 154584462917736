@import "styles/colours.scss";
@import "styles/constants.scss";

.previewWrapper {
  display: flex;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-right: 1px solid $grey50;
  height: 100vh;
}

.closeIcon {
  color: $colorPrimaryText;
}

.controlsBtn {
  border-color: $grey50;
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey50;
}

.searchBtn {
  padding: 8px;
}
