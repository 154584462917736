@import "styles/colours.scss";

.modal {
  font-family: "Inter", "Lato", sans-serif;
  padding: 4px;

  .modalDescription {
    font-family: inherit;
    color: $v2-text-secondary;
  }
}
