@import "styles/colours.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  h3 {
    margin-bottom: 0px;
  }
}

.count {
  font-size: 14px;
  font-weight: 500;
}

.arrows {
  display: flex;
  gap: 4px;
}

.arrowContainer {
  cursor: pointer;
  padding: 8px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.disabled {
  cursor: default !important;
  color: $disabled-grey;

  svg {
    cursor: default !important;
  }
}

.label {
  font-size: 12px;
  color: $grey300;
  margin-bottom: 4px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.snippet {
  word-wrap: break-word;
  cursor: pointer;
}

.activeSnippet {
  background-color: $blue50;
  border: 1px solid $colorPrimary;
  border-radius: 4px;
  padding: 8px;
}

.results {
  width: 440px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: scroll;
  padding: 24px;
  min-height: 190px;
}

.resultsWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.highlight {
  background-color: $blue100;
}

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;

  h3 {
    font-weight: 700;
    font-size: 16px;
    padding-top: 24px;
  }

  img {
    width: 56px;
    height: 56px;
  }
}
