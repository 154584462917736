@import "styles/media_queries.scss";
@import "styles/colours.scss";

.invitationExpiredPage {
  display: flex;
  width: 100%;
  justify-content: center;
  .innerWrapper {
    text-align: center;
    max-width: 400px;
    margin-top: 10vh;
    @media (min-width: map-get($breakpoints, xxl)) {
      margin-top: 20vh;
    }
  }

  h1 {
    font-size: 48px;
    line-height: 62px;
    color: $dark-blue;
    font-weight: normal;
  }

  button {
    padding: 0;
    font-weight: bold;
  }

  .actionItemsContainer {
    margin-top: 80px;
  }

  .actionItems {
    display: block;
    margin: 0 auto 15px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }
}
