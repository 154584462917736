@import "styles/colours.scss";

.newSuggestedQuestions {
  display: flex;
  flex-direction: row;

  .suggestionColumn {
    width: 50%;
    display: flex;
    flex-direction: column;

    button {
      text-align: left;
      white-space: normal;
      padding-left: 12px;
      padding-right: 12px;
      height: fit-content;
      border-radius: 4px;
      margin: 6px;
      min-height: 76px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > p {
        margin: 0;
      }

      .line1 {
        font-weight: 550;
      }

      .line2 {
        font-weight: 400;
      }
    }
  }
}
