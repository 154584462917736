@import "styles/colours.scss";

.experienceContainer {
  .progressBar {
    max-width: 200px;
    margin-bottom: 56px;
  }

  .headerText {
    padding-bottom: 26px;
    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: $text-primary-v2;
    }

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $v2-text-secondary;
    }
  }

  .radioOption {
    padding-bottom: 24px;
    max-width: 405px;

    .radioLabel {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $text-primary-v2;
    }

    .radioInput {
      margin-right: 8px;
    }

    .subRadioText {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $text-primary-v2;
    }
  }

  .selectSection {
    display: flex;
    flex-direction: column;
    background-color: $colorPrimaryBg;
    padding: 16px;
    border-radius: 8px;

    .helperText {
      padding-top: 8px;
      margin: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $text-primary-v2;
    }
  }
}
