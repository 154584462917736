@import "styles/colours.scss";

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 24px 40px 24px;
  width: 440px;
  border-bottom: 1px solid $grey50;

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
  }
}

.ellipsis {
  color: $colorPrimaryText;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  max-height: 240px;
  max-width: 300px;
  overflow: scroll;
  padding: 4px;
  gap: 8px;
}

.emptyState {
  color: $grey300;
}
