@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  gap: 8px;
}

.searchInput {
  width: 320px;
}

.filterIcon {
  color: $colorPrimary;
}

.uncollectedState {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $grey50;
  border-radius: 4px;
  padding: 24px;
  gap: 8px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    max-width: 300px;
    margin: 0;
  }

  p {
    text-align: center;
    max-width: 300px;
    margin: 0;
    padding-bottom: 8px;
  }

  img {
    height: 140px;
  }
}
