@import "styles/colours";
@import "styles/fonts";
@import "styles/layouts";

.section {
  padding: 24px 0px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: $text-primary;
  text-align: left;
  margin-left: 0;
}

.subtitle {
  @extend .title;
  font-size: 20px;
  margin-bottom: 16px;
}

.smallerTitle {
  @extend .title;
  font-size: 16px;
  margin-bottom: 16px;
}

.frameworkDetailsContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 64px;
  width: 1280px; // needs to be fixed for header alignment
}

.summaryDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .detailLabel {
    align-items: center;

    .title {
      min-width: 112px;
      max-width: 112px;
    }
  }
}

.contactLabel {
  //should match detailLabel so labels align
  min-width: 112px;
  max-width: 112px;
}

.summaryDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0px;
  min-width: 440px;
  max-width: 440px;
}

.detailsContainer {
  max-width: 55vw;
}

.description {
  white-space: pre-wrap;
  // white-space: pre-line;
  display: block;
  margin: 0px;
  line-height: 28px;
  color: $dark-grey;

  a {
    word-break: break-all;
  }
}

.lotsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 24px;
  max-width: fit-content;

  .lotTitle {
    @extend .title;
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
  }
}

.lotContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 24px;
  border-left: 2px solid $grey50;
}

.lotProperties {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: fit-content;
}

.sourceLink {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
}

.divider {
  color: $grey50;
  margin: 0;
}

@media screen and (max-width: 880px) {
  .summaryDetailsContainer {
    order: 1;
  }

  .detailsContainer {
    order: 2;
  }

  .frameworkDetailsContent {
    flex-direction: column;
  }
}
