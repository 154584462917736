@import "styles/colours";
@import "styles/layouts";
@import "styles/fonts";

.headerContent {
  padding: 0px;

  .icons {
    min-width: 48px;
  }
}

.topLink {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .backLink {
    font-weight: 700;
  }

  span {
    color: $text-secondary;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  padding: 24px 0px;

  h1 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $dark-blue;
    font-family: $font-default;
  }

  .title {
    display: inline-flex;
    gap: 8px;
    align-items: baseline;
  }

  .rightSide {
    display: flex;
    align-items: center;
    gap: 8px;

    margin-left: auto;
  }
}

.stageAndRelativeTime {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $text-secondary;
}
