@import "../../../styles/colours.scss";

.guestPassHeader {
  display: flex;
  padding: 16px 32px 16px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: radial-gradient(
    circle at 90% 10%,
    $mid-blue 15%,
    $colorPrimary 1%,
    $colorPrimary 20%
  );
  color: $white;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 24px;
  gap: 40px;
}

.guestPassInfo {
  display: flex;
  padding: 5px 12px 5px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: $mid-blue;
}

.info {
  display: flex;
  gap: 12px;
}

.btnContainer {
  display: flex;
  gap: 8px;
  font-weight: 500;
}

.btn {
  display: flex;
  align-items: center;
}

.link {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
