@import "styles/colours.scss";

.loading {
  color: $sysTextSecondary;
}

.warningTooltip {
  padding: 8px;
  max-width: 320px;
  border-radius: 4px;
  background-color: #1a3369;

  [role="tooltip"] {
    background-color: #1a3369;
    border: none;
    box-shadow: none;
  }
}

.warningIcon {
  padding-right: 3px;
}

.noticeCount {
  padding-left: 3px;
}

.customTooltipContent {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: $white;
  background-color: #1a3369;

  .customTooltipTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 0px;
  }

  .customTooltipDescription {
    font-size: 14px;
    padding-bottom: 0px !important;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 0px;
  }
}
