@import "styles/colours";
@import "styles/layouts";

.card {
  display: flex;
  flex: 0 1 240px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(90deg, #003cba 0%, $mid-blue 100%);
  padding: 12px;
  border-radius: $border-radius-md;
  color: $white;
  border: 1px solid $colorPrimaryBorder;

  &:hover {
    background: linear-gradient(90deg, #04369f 0%, #2569f9 100%);
  }

  &:active {
    background: linear-gradient(90deg, #04369f 0%, #074fe9 100%);
  }

  .sparkles {
    width: 32px;
  }

  .text {
    flex-direction: column;
    padding-left: 12px;
    h3 {
      margin-bottom: 4px;
      color: $white;
      font-size: 14px;
      line-height: 20px;
    }

    p {
      margin-bottom: 0;
      color: $white;
    }
  }
}
