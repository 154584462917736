@import "styles/colours";

.titleAndDesc {
  display: flex;
  min-width: 80%;
  flex-direction: column;

  .currentViewTitle {
    padding: 0px;
    margin-bottom: 0px;
    border: none;
    background-color: white;
    font-weight: 700;
    font-size: 24px;
    color: $text-primary-v2;
    cursor: default;
  }

  .currentViewDescription {
    padding: 0px;
    margin: 4px 0px;
    border: none;
    background-color: white;
    resize: none;
    color: $text-primary-v2;
    cursor: default;
  }
}
