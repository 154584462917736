@import "styles/colours.scss";

.menuTabs {
  font-size: 14px;
  font-weight: 500;
  border-bottom: none;
  color: $dark-grey;
  margin-top: 12px;

  .menuTabItem {
    padding: 0px 16px;
  }
}
