@import "styles/colours.scss";

.tipsModalSubtitle {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
  & + em {
    color: $dark-blue;
    margin-bottom: 28px;
    display: block;
  }
}

.tipsSection {
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 0px;
  }

  p {
    color: $dark-blue;
    margin-bottom: 0;
  }
  li {
    color: $dark-grey;
  }
}
