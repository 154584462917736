@import "styles/colours.scss";

.content {
  color: $black;
}

.title {
  font-size: 16px;
  margin-bottom: 8px;
  color: $dark-grey;
  font-weight: 700;
}

.overlay {
  width: 330px;
}
