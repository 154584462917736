@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 0px 0px 0px;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
}

.actions {
  display: flex;
  gap: 8px;
}

.searchInput {
  width: 320px;
}
