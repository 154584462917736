.pageContainer {
  flex: 1;
  margin: 20px;
}

.buttonContainer {
  margin: 20px;
  justify-content: center;
  display: flex;
  gap: 20px;
}

.searchButton {
  margin-bottom: 20px;
  margin-top: 20px;
}

.backButton {
  margin-bottom: 20px;
}
