@import "styles/colours.scss";

.selectAnchorPage {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
  padding: 8px;
  grid-gap: 8px;
  background-color: $white;

  .searchPanel {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
  }

  .resultPanel {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selectedAnchorCard {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    padding: 16px;
    border-radius: 4px;
  }
}
