@import "styles/colours";
@import "styles/layouts";

.wrapper {
  display: block;
  grid-template-columns: auto min-content;
  align-items: center;
}

.placeholderIcon {
  font-size: 48px;
  color: $dark-blue;
}

.inlineButtons {
  display: flex;
  grid-column-gap: 8px;
}

.inlineTitleActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}

.title {
  display: inline-flex;
  align-items: center;
  grid-column-gap: 16px;

  h1 {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $dark-blue;
  }

  img {
    border-radius: 1px;
    // for alt text
    font-size: 7px;
    overflow: hidden;
  }
}

.stats > div {
  display: inline-block;
  vertical-align: top;

  p {
    line-height: 21px;
  }
}

.statistic {
  margin-right: 24px;
}
