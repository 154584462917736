@import "styles/colours.scss";

.line {
  border: 1px solid $light-grey2;
}

.horizontal {
  width: 100%;
}

.vertical {
  height: 100%;
}
