@import "styles/colours.scss";
@import "styles/layouts.scss";
@import "styles/fonts.scss";

.headerBar {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .titleContainer {
    .pageHeader {
      font-size: 24px;
      font-weight: 700;
      padding-top: 4px;
      margin: 0;
    }
  }
}

// .menuTabsContainer {
//   border-bottom: $border-basic-light;
//   margin-bottom: 16px;
// }
//
//
// .menuTabs {
//   font-size: 14px;
//   font-weight: 600;
//   color: $dark-grey;
//   border-bottom: none;
//   margin-left: 16px;
//   margin-top: 4px;

//   .menuTabItem {
//     padding: 0;
//     margin-right: 16px;
//   }
// }

.relatedContactsContent {
  display: flex;
  flex-direction: row;
  background-color: $white;
  border-radius: 4px;
  padding: 0px;
  min-width: 100%;
}

.recordPreviewContent {
  @extend .relatedContactsContent;
  flex-direction: column;
  min-width: 380px;
  max-width: 450px;
  padding: 16px;
}

.relatedContactsTable {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  flex-grow: 1;
  padding: 0px;
  border-radius: 4px;
  margin-left: 0px;
}

.headerText {
  color: $v2-dark-blue;
}

.relatedRecordSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 100%;
  font-family: $font-default;
}

.recordTitle {
  color: $v2-dark-blue;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.divider {
  margin-top: 8px;
  margin-bottom: 16px;
}

.detailSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.subheading {
  color: $disabled-grey;
  font-size: 12px;
  margin-bottom: 0;
}
