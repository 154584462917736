@import "styles/colours.scss";

.choice {
  line-height: 18px;
  height: 26px;
  display: flex;
  align-items: center;
  margin: 4px;
  padding: 4px 8px;
  color: $dark-grey;

  &:hover {
    cursor: pointer;
    border-width: 2px;
  }

  &.partner {
    background-color: $light-yellow;
  }

  &.competitor {
    background-color: $light-red;
  }
}

.addAllSelectOption {
  color: $mid-blue;
}

.selectedItem {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;
  max-width: 400px;
}
