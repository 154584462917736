@import "../../styles/fonts.scss";
@import "../../styles/colours.scss";

.onboardingForm {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  label h3 {
    margin-bottom: 0px;
  }
}

.onboardingForm input {
  display: flex;
  width: 320px;
}

.submitButton {
  max-width: 110px;
  margin-top: 16px;
}
