@import "styles/colours.scss";

.titleColumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.title {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}

.titleText {
  font-weight: bold;
}

.documentIcon {
  flex-shrink: 0;
}

.paywallStar {
  flex-shrink: 0;
}

.pagination {
  display: flex;
  margin: 16px 0px;
  justify-content: center;
}

.linkIcon {
  width: 16px;
  height: 16px;
}

.bannerImg {
  width: 64px;
  height: 64px;
  padding: 0px 6px;
  flex-shrink: 0;
}

.actions {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.download {
  color: $link-blue-default;
}

.delete {
  color: $red;
}
