@import "styles/colours.scss";

.qaReportSection {
  border-bottom: 1px solid $grey50;

  &[open] {
    > *:not(summary) {
      animation: details-show 0.3s ease-out;
    }
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  list-style: none;
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 24px;
  width: 100%;
}

.icon {
  padding-right: 24px;
}

.qaSection {
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
}

@keyframes details-show {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
