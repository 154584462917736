@import "../form_components/Inputs.module.scss";

.form {
  @extend .flexColumn;
  width: 100%;
}

.inputLabel {
  @extend .label;
}

.sectionTitle {
  font-weight: 700;
}

.filterSection {
  @extend .form;
  gap: 16px;
}

.inputContainer {
  @extend .flexColumn;
}

.valueFields {
  display: flex;
  gap: 4px;
  align-items: center;
}

// Hide for now as we test with users
// TODO: enable or remove once testing complete
// .newIcon {
//   color: $colorPrimary;
//   background-color: $blue50;
//   padding: 4px 6px;
//   border-radius: 4px;
// }
