@import "styles/colours";

.signalIcon {
  color: $colorPrimary;
}

.saveSignalBtn {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  width: 100%;
}

.external {
  height: 13px;
  width: 13px;
}

.viewSigSettings {
  margin-left: 8px;
  padding: 4px 8px;
}
