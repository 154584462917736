@import "styles/colours.scss";

.pageHeader {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid $light-grey;
  padding: 24px 24px 0px 24px;
}

.headerComponents {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%; // needed to ensure header doesn't disappear on small screen
  align-self: center;

  .menuTabs {
    font-size: 14px;
    font-weight: 500;
    color: $white; //$dark-grey;

    ::before {
      border-bottom: 0px; //removes antd line
    }
  }
}

.pageWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px 24px 24px 24px;
}

.tableContainer {
  padding: 24px 0px;
  max-width: 1280px;
  flex: auto;
}
