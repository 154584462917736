@import "styles/colours.scss";

.linkContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.linkTextContainer {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 230px;
  color: $blue500;
}

.linkText {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.icon {
  margin: 0px;
}

.sourceProperty {
  color: $colorPrimary;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.popoverContent {
  display: flex;
  flex-direction: column;
  max-height: 240px;
  max-width: fit-content;
  padding: 4px;
  padding-top: 0px;
  gap: 8px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
    float: right;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $placeholder-grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $placeholder-grey;
  }
}

.popoverTitle {
  padding: 4px;
  padding-bottom: 0px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
}
