@import "styles/colours.scss";
@import "styles/constants.scss";

.container {
  display: flex;
  flex-direction: column;
  width: calc(100vw - $sidebarWidthExpanded);
  min-height: 100vh;
  background-color: $white;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;

  background-color: $white;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 24px 16px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.headerTitle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.title {
  margin: 0;

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.headerActions {
  display: flex;
  gap: 8px;
}

.subtitle {
  max-width: 826px;
  margin: 0;

  color: $text-secondary;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.content {
  display: flex;
  gap: 16px;

  width: 100%;

  padding: 0 16px;
  margin-bottom: 24px;
}

.signalSettingsContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;

  width: 60%;
}

.noticesContainer {
  flex-grow: 1;

  position: sticky;
  top: 16px;

  width: 40%;
  max-height: 100vh;
}

.footer {
  display: flex;
  gap: 8px;
  padding: 24px;
  background-color: $white;
  border-top: 1px solid $grey50;
  border-bottom: 1px solid $grey50;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  z-index: 10;
}
