@import "styles/colours.scss";
@import "styles/fonts.scss";

.content {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: $dark-blue;
  margin-bottom: 8px;
}
