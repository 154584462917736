@import "styles/colours.scss";

.pageContent {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 24px;
  background-color: $white;
  height: calc(100% - 57px);
}

.section {
  max-height: fit-content;
  margin-bottom: 48px;
}

.selectComponents {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.tag {
  display: flex;
  max-width: max-content;
  max-height: 60px;
  padding: 5px 12px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    border-width: 2px;
  }
}

.selectedItem {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;
  font-size: 16px;
}

.noSignals {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
