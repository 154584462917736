@import "styles/colours.scss";

.countriesField {
  .label {
    font-size: 18px;
    line-height: 22px;
    color: $dark-blue;
    display: block;
    margin-bottom: 10px;
  }

  .optionsContainer {
    & > div {
      display: inline-block;
    }
  }
}
