@import "../form_components/Inputs.module.scss";
@import "../../styles/colours.scss";

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionTitle {
  font-weight: 700;
}

// Delete when feature flag is removed
.oldFilterSection {
  @extend .form;
  gap: 16px;
}

.inputContainer {
  @extend .flexColumn;
}

.inputLabel {
  @extend .label;
}

.valueFields {
  display: flex;
  gap: 4px;
  align-items: center;
}

.tooltip {
  margin: 0px 32px;
}

.mentionsAwardsWrapper {
  padding: 24px;
  border-bottom: 1px solid $grey50;
}

// Hide for now as we test with users
// TODO: enable or remove once testing complete
// .newIcon {
//   color: $colorPrimary;
//   background-color: $blue50;
//   padding: 4px 6px;
//   border-radius: 4px;
// }
