@import "styles/tag_helpers.module";
@import "styles/colours.scss";
@import "styles/layouts";

.filtersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px;
  margin: 24px;
  margin-bottom: 8px;
}

.buyerFilters {
  display: inherit;
  gap: 8px;
}

.searchInput {
  width: 250px;
  color: $v2-text-secondary;

  .searchIcon {
    color: $colorPrimary;
  }
}

.filter {
  margin-right: 8px;
}

.clearFilters {
  font-weight: 700;
  padding-left: 5px;
}

.filterOverlay {
  max-height: calc(100vh - 210px);
}

.optionDescription {
  display: block;
  font-size: 12px;
  color: $secondary-text-grey;
  padding-bottom: 4px;
}

.countryRegionOverlay {
  min-width: 300px;
}
