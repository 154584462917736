@import "styles/colours.scss";
.content {
  padding-left: 30px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.completeTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.completeContent {
  font-size: 14px;
  line-height: 22px;
}

.info {
  color: $colorPrimary;
  font-size: 22px;
}
