@import "styles/colours.scss";
.editAnswerContainer {
  cursor: pointer;
}

.inputContainer {
  border: 1.5px solid $sysPrimaryDefault;
}

.markdown {
  border-radius: 4px;
  padding: 4px 11px;
  border: 1.5px solid $white; // Essentially an invisible border, so things don't jitter

  &:hover {
    border: 1.5px solid $sysPrimarySubtleAlt;
  }

  p {
    margin: 0;
  }
}

.heading {
  font-size: 14px !important;
  font-weight: 700 !important;
  margin: 8px 0px;
  color: $colorTextBase;
}
