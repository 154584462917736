@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 24px;

  border-bottom: 1px solid $sysBorderPrimary;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 12px;
  height: 12px;
}

.refreshIcon {
  @extend .icon;

  color: $sysPrimaryDefault;
}

.titleHeader {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  margin: 0;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.subtitle {
  margin: 0;

  color: $text-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.suggestedKeywordsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
