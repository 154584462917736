@import "styles/colours.scss";
@import "styles/fonts";

.preauthenticationPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: white;
  max-width: 325px;
  max-height: 592px;
  margin: 100px auto 0;

  p {
    color: $colorTextBase;
  }

  h1,
  p,
  span,
  h3 {
    font-family: $font-default;
  }
}

.submitButton {
  margin-top: 20px;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 3px;
  height: 52px;
  font-size: 16px;
  font-weight: 100;
  background-color: $colorPrimary;
}

#email {
  font-family: $font-default;
}

.googleLogo {
  height: 20px;
  width: 20px;
  display: inline-block;
  background-size: cover;
  background-image: url("../../../assets/images/icons/google_logo.svg");
}

.microsoftLogo {
  height: 20px;
  width: 20px;
  display: inline-block;
  background-size: cover;
  background-image: url("../../../assets/images/icons/microsoft_logo.svg");
}

.emailInput {
  height: 52px;
  border-radius: 3px;
}

.socialConnectionButton {
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  box-shadow: none;
  height: 52px;
  margin-bottom: 8px;
  span {
    font-size: 16px;
    font-weight: 100;
    color: #2d333a;
    font-style: normal;
  }
}

.footer {
  margin-top: 30px;
}

.emailInput:focus {
  border-width: 2px;
}

.link {
  color: $colorPrimary;
}

.socialConnectionButton:hover {
  background-color: $grey50;
  border-color: $grey50 !important;
  color: $colorTextBase;
}

.lineContainer {
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
  margin-top: 20px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #e6e7ea;
}

.word {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4f5a6f;
  background-color: white;
  padding: 0 10px;
  white-space: nowrap;
  font-size: 12px;
}

.preauthenticationContainer {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}
.header {
  padding-bottom: 10px;
}

.headerTitle {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  font-weight: 700;
}

.description {
  color: $colorTextBase;
}

.alternativeAuth {
  font-size: 14px;
  margin-bottom: 0px;

  .link {
    padding: 4px;
  }
}
