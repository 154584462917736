@import "styles/colours";

.emptyState {
  text-align: center;
  background: $white;
  padding: 72px;
}

.emptyStateImage {
  svg {
    transform: scale(1.75);
  }
}

.emptyStateBetaMsg {
  max-width: 480px;
  margin: 0 auto;
  margin-top: 24px;
}

.initialStateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 24px 72px 72px;

  h2,
  h3 {
    font-weight: 700;
  }
}

.startHereContainer {
  align-self: flex-start;
}

.viewArrow {
  transform: scaleX(-1);
}

.mainTitleContainer {
  text-align: center;
}

.startHereArrowContainer {
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.infoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 120px;
  margin-top: 30px;
  text-align: center;
}

.infoGridImage {
  align-self: center;
  justify-self: center;
  margin-bottom: 24px;
}

.highlightBox {
  padding: 6px 8px;
  border-radius: 2px;
  background: linear-gradient(270deg, #4f86fa 0%, #003cba 190.8%);
  color: white;
  font-weight: 800;
  font-size: 12px;
  text-align: center;
  min-width: 110px;
  align-self: center;
  justify-self: center;
}

.topicSpendComingSoon {
  @extend .highlightBox;
  grid-column: 3;
}
