@import "styles/colours.scss";
@import "styles/media_queries.scss";
@import "styles/fonts.scss";

.skeletonWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 24px;
}

.skeleton {
  width: 100%;
  max-width: 1280px;
}

.pageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  isolation: isolate;
}

.contentContainer {
  display: flex;
  justify-content: center;
  padding: 24px;
}

.columnsContainer {
  gap: 64px;
}

.header {
  display: flex;
  border-bottom: 1px solid $grey50;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 1;
  padding: 24px;
  justify-content: center;
}

.scroll {
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.12);
  padding-top: 16px;
  padding-bottom: 16px;
}

.redactedHeader {
  padding: 24px 24px 12px 24px;
  border-bottom: 1px solid $grey50;
}
