@import "styles/colours.scss";

.exportIcon {
  color: $colorPrimary;
}

.chatButton {
  color: $colorPrimary;
  padding: 0;
}
