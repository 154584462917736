@import "../../styles/colours";

.headerContainer {
  display: grid;
  // minimise the first column (icon) if it's not there
  grid-template-columns: min-content 1fr;
  // minimise the second row (subheader) if it's not there
  grid-template-rows: 1fr min-content;
}

.header {
  grid-column: 2 / 2;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000 85%;
}

.headerIcon {
  // Takes up both rows an is centered veritcally
  grid-row: 1 / -1;
  align-self: center;
  margin-right: 8px;
}

.headerIconWarning {
  color: $antWarningColour;
}

.subHeader {
  grid-column: 2 / 2;
  font-size: 12px;
  line-height: 16px;
  color: #000 55%;
  font-weight: 500;
}
