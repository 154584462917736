@import "styles/colours.scss";

.container {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  column-gap: 3px;

  h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
  }
}

.optionDescription {
  display: block;
  font-size: 12px;
  color: $secondary-text-grey;
  padding-bottom: 4px;
}
