@import "styles/colours";
@import "styles/layouts";

.card {
  flex: 0 1 240px;
  cursor: pointer;
  background-color: $white;
  padding: 12px;
  border-radius: $border-radius-md;
  border: $border-basic-mid;

  h2,
  h3 {
    color: $colorPrimary;
    display: inline-block;
    margin-bottom: 4px;
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
  }

  h3 {
    font-size: 14px;
    line-height: 20px;
    margin-left: 8px;
  }

  p {
    margin-bottom: 0;
    color: $v2-text-secondary;
  }

  &.active {
    background-color: $colorPrimary;
    color: $white;
    border-radius: $border-radius-md;
    border: 1px solid $colorPrimaryBorder;

    h2,
    h3 {
      color: inherit;
    }

    p {
      color: inherit;
    }
  }

  &:hover:not(.active) {
    background-color: $pale-blue;

    h2,
    h3 {
      color: $text-dark-blue;
    }
  }
}
