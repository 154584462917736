@import "styles/colours.scss";
@import "styles/tag_helpers.module.scss";

.recordCard {
  margin: 16px;

  &:first-child {
    margin-top: 0px;
  }

  border: 1px solid $legacy-border-grey;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
}

.matchReasonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  .publishDate {
    font-size: 12px;
    line-height: 18px;
    color: $grey;
    white-space: nowrap;
    min-width: min-content;
    margin: 0;
  }
}

.infoContainer {
  width: 100%;

  .title {
    font-size: 16px;
    line-height: 24px;
    color: $dark-blue;
    font-weight: 400;
    margin: 0;
    display: inline;
  }

  .stageLabel {
    margin-right: 8px;
  }

  .buyerName {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    color: $dark-grey;
  }
}

.layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  padding: 24px;
}

.inputPanel {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  padding: 16px;
}

.or {
  padding-left: 48px;
  margin: 12px 0;
}

.resultsPanel {
  padding-top: 16px;
  max-height: calc(100vh - 16px);
  overflow-y: scroll;

  h2 {
    padding-left: 16px;
    margin-bottom: 0;
  }
}

.form {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: end;
}

.header {
  display: grid;
  grid-template-columns: 7fr 1fr;
  align-items: center;
}

.otherMatchedFrameworkId {
  border-color: red;
}

.keywordMatch {
  @extend .keywordMatchTag;
}

.matchItem {
  @extend .nonTagCellTag;
}

.matchList {
  @extend .nonTagCellTagsContainer;
}
