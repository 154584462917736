@import "styles/colours.scss";

.settingsContainer {
  width: 525px;
  margin: 16px auto;

  h1 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: $dark-blue;
    margin: 0;
  }
}

.content {
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: minmax(0, 1fr);
}
