@import "styles/colours";

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.filterName {
  font-weight: 500;
}

.inputField {
  flex-grow: 1;
  padding: 0px 8px;
  min-height: 32px;
}

.filterLabelDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;

  &[data-label="false"] {
    height: 0px;
  }
}

.clearButton {
  padding: 0px;
  border: none;
  box-shadow: none;
  color: $colorPrimary;
  width: fit-content;

  &:hover {
    color: $colorPrimary;
  }
}

.icon {
  color: $colorPrimary;
}

.excludeTag {
  background-color: $sysDestructiveSubtle;
  border: 1px solid $sysDestructiveSubtleAlt;
  stroke: $sysDestructiveSubtleAlt;
}
