.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formActions {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
