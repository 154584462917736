@import "styles/colours.scss";

.relevanceScoreButtonContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 0px 8px;
}

.newRelevanceScoreButton {
  display: flex;
  vertical-align: middle;
  gap: 8px;
  align-items: center;
  padding: 2px;
  border-radius: 8px;
}
