@import "styles/colours.scss";

.linksContainer {
  display: flex;

  .logOutLink {
    align-items: center;
    color: $link-blue-default;
    font-size: 13px;
    font-weight: bold;

    :hover {
      color: $link-blue-hover;
    }
  }
}
