@import "styles/colours.scss";

.suggestedQuestions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  padding-top: 150px;
  padding-bottom: 24px;

  button {
    text-align: left;
    white-space: normal;
    padding-left: 12px;
    padding-right: 12px;
    height: fit-content;
    border-radius: 4px;
    margin: 6px;
    min-height: 76px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > p {
      margin: 0;
      color: $sysTextSecondary;
    }

    .title {
      font-weight: 550;
    }

    .samplePrompt {
      font-weight: 400;
    }
  }
}
