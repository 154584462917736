@import "styles/colours.scss";

.countriesField {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .label {
    font-size: 18px;
    line-height: 22px;
    color: $dark-blue;
    display: block;
  }
}
